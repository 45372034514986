.section {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.title {
    font-family: Avenir;
    font-weight: 800;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -2%;
    color: #000000;
    text-align: left;
    margin: 0;
    padding: 0;
    margin-bottom: 8px;

    @media (max-width: 1020px) {
        margin-bottom: 24px;
        font-size: 24px;
        line-height: 100%;
    }
}

.option {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 1020px) {
        margin-bottom: 24px;
    }
}

.dataBlock {
    display: flex;
    flex-direction: column;
    padding-top: 32px;
    margin-bottom: 32px;

    @media (max-width: 1020px) {
        padding-top: 0;
        margin-bottom: 0;
    }
}

.guestBlock {
    display: flex;
    flex-direction: column;
}

.textLabel {
    font-family: Avenir;
    font-weight: 800;
    font-size: 18px;
    line-height: 24.59px;
    letter-spacing: -2%;
    color: #000000;
    margin: 0;
    padding: 0;
    margin-bottom: 4px;
    text-align: left;

    @media (max-width: 1020px) {
        font-size: 16px;
        line-height: 100%;
        letter-spacing: 0%;
    }
}

.valueOption {
    font-family: Avenir;
    font-weight: 400;
    font-size: 16px;
    line-height: 21.86px;
    letter-spacing: 0%;
    color: #747E7F;
    margin: 0;
    padding: 0;
    text-align: left;

    @media (max-width: 1020px) {
        font-size: 14px;
        line-height: 100%;
    }
}

.buttonEdit {
    border: none;
    margin: 0;
    padding: 0;
    background: none;
    font-family: Avenir;
    font-weight: 400;
    font-size: 18px;
    line-height: 24.59px;
    letter-spacing: -2%;
    color: #7623FF;
    text-align: right;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;

    @media (max-width: 1020px) {
        font-size: 16px;
        line-height: 100%;
        letter-spacing: 0%;
    }
}

.optionGuests {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #E2E2E2;
    padding-top: 32px;
    margin-bottom: 32px;
    position: relative;

    @media (max-width: 1020px) {
        padding-top: 0;
        margin-bottom: 24px;
        border-top: none;
        border-bottom: 1px solid #E2E2E2;
        padding-bottom: 24px;
    }
}

.date-picker {
    position: absolute;
    bottom: 56px;
    left: 30px;
    z-index: 3000;
    padding: 24px;
    background: white;
    box-shadow: 0px 25px 50px -12px #00000040;
    border-radius: 16px;
    width: max-content;
    background-color: white;
    box-sizing: border-box;
}

.guestModal {
    position: absolute;
    bottom: -100px;
    right: 312px;
}