.modal {
    position: absolute;
    bottom: 60px;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 4000;

    @media (max-width:1020px) {
        bottom: 0;
        left: 0;
        right: 0;
        position: fixed;
        max-width: 100%;
        overflow-x: hidden;
        width: 100vw;
    }
}

.content {
    width: 297px;
    height: 98px;
    background: #ffffff;
    box-shadow: 0px 25px 50px -12px #00000040;
    border-radius: 16px;
    position: relative;
    z-index: 10;

    @media (max-width:1020px) {
        height: 298px;
        width: 100%;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }
}

.sliderWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 249px;
    height: 12px;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 43px;

    @media (max-width:1020px) {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 39px;
        height: 90px;
        box-sizing: border-box;
    }
}

.slider {
    width: 100%;
    padding: 6px 0;
    box-sizing: border-box;
    cursor: pointer;
}

.thumb {
    width: 12px;
    height: 12px;
    background: #7623FF;
    border-radius: 50%;
    border: none;
    cursor: grab;
    margin-top: -4px;
    margin-left: 1px;

    &:focus {
        outline: none;
    }
}

.track {
    border: 3px solid #7623FF;
    left: 2px !important;
}

.trackRight {
    border: 1px solid #E2E2E2;
    background: none;
}

.value {
    color: #000;
    position: absolute;
    top: 21px;
    left: 50%;
    transform: translateX(-50%);
    font-family: Avenir;
    font-size: 14px;
    font-weight: 800;
    line-height: 19.12px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    white-space: nowrap;

    @media (max-width:1020px) {
        top: 86px;
    }
}

.minMax {
    height: 19px;
    padding-bottom: 24px;
    padding-left: 24px;
    padding-right: 24px;
    display: flex;
    justify-content: space-between;

    @media (max-width:1020px) {
        padding: 0;
        width: 100%;
    }
}

.text {
    font-family: Avenir;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.12px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin: 0;
    padding: 0;
    color: #747E7F;
}

.mobile {
    display: none;

    @media (max-width:1020px) {
        display: flex;
        width: 100%;
    }
}

.top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 65px;
    padding-left: 20px;
    padding-right: 20px;
}

.textTop {
    font-family: Avenir;
    font-size: 18px;
    font-weight: 800;
    line-height: 24.59px;
    letter-spacing: -0.02em;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #000000;
    margin: 0;
    padding: 0;
}

.buttonClose {
    width: 24px;
    height: 24px;
    cursor: pointer;

    &:hover {
        opacity: 0.7;
    }
}

.bottom {
    width: 100%;
    display: flex;
    height: 86px;
    padding: 20px;
    box-sizing: border-box;
    margin-top: 57px;
    align-items: center;
}

.buttonDone {
    width: 100%;
    height: 54px;
    border-radius: 16px;
    border: none;
    background: #7623FF;
    font-family: Avenir;
    font-size: 18px;
    font-weight: 400;
    line-height: 24.59px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #ffffff;
    margin: 0;
    padding: 0;
    cursor: pointer;

    &:hover {
        opacity: 0.7;
    }
}

.desktop {

    @media (max-width:1020px) {
        display: none;
    }
}