.notification {
    position: fixed;
    top: 20px;
    right: 20px;
    padding: 10px 20px;
    border-radius: 5px;
    color: #fff;
    font-size: 14px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
    z-index: 5000;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 300px;
    animation: fadeIn 0.3s ease-in-out;
  
    &.notification-success {
      background-color: #4caf50;
    }
  
    &.notification-error {
      background-color: #f44336;
    }
  
    &.notification-warning {
      background-color: #ff9800;
    }
  
    .close-button {
      margin-left: 10px;
      background: transparent;
      border: none;
      color: #fff;
      font-size: 16px;
      cursor: pointer;
    }
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  