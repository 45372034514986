.pagination {
    display: flex;
    gap: 16px;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
    padding-top: 24px;
    padding-bottom: 24px;

    button {
        border: none;
        background: none;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 36px;
        height: 36px;
        cursor: pointer;
        font-family: Avenir;
        font-size: 16px;
        font-weight: 500;
        line-height: 21.86px;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #000000;

        img {
            width: 20px;
            height: 20px;
        }

        &:disabled {
            opacity: 0.25;
            cursor: not-allowed;
        }

        &.active {
            color: #fff;
            background: #7623ff;
            border-radius: 50%;
        }
    }
}

.ellipsis {
    border: none;
    cursor: pointer;
    background: none;
    width: 36px;
    height: 36px;
    font-family: Avenir;
    font-size: 16px;
    font-weight: 500;
    line-height: 21.86px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
}