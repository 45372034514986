.place-features {
    width: 897px;
    margin-bottom: 54px;
    border-top: 1px solid #E2E2E2;

    @media (max-width: 1020px) {
        width: 100vw;
        margin-bottom: 24px;
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
    }

    .place-features-heading {
        margin: 0;
        padding: 0;
        padding-top: 32px;
        margin-bottom: 32px;
        font-family: Avenir;
        font-size: 32px;
        font-weight: 800;
        line-height: 40px;
        letter-spacing: -0.02em;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #000000;

        @media (max-width: 1020px) {
            padding-top: 24px;
            margin-bottom: 24px;
            font-size: 24px;
            line-height: 32.78px;
        }
    }

    .place-features-list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 32px 24px;
        margin: 0;
        padding: 0;
        list-style: none;

        @media (max-width: 1020px) {
            display: flex;
            flex-direction: column;
            gap: 0;
        }

        .place-feature-item {
            display: flex;
            align-items: center;
            gap: 16px;

            @media (max-width: 1020px) {
                gap: 0;
                margin-bottom: 16px;
                align-items: unset;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            .place-feature-icon {
                width: 24px;
                height: 24px;

                @media (max-width: 1020px) {
                    margin-right: 16px;
                }
            }

            .place-feature-name {
                padding: 0;
                margin: 0;
                font-family: Avenir;
                font-size: 18px;
                font-weight: 400;
                line-height: 24.59px;
                letter-spacing: -0.02em;
                text-align: left;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;
                color: #000000;

            }
        }
    }

    .place-features-toggle {
        display: block;
        width: 100%;
        height: 54px;
        text-align: center;
        background: none;
        border: 1px solid #7623FF;
        color: #7623FF;
        border-radius: 16px;
        cursor: pointer;
        margin-top: 32px;
        font-family: Avenir;
        font-size: 18px;
        font-weight: 400;
        line-height: 24.59px;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;

        @media (max-width: 1020px) {
            margin-top: 24px;
        }


        &:hover {
            opacity: 0.7;
        }
    }
}