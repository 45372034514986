.dashboard {
  background-color: #FAFBFC;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 54px;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.profile {
  display: flex;
  align-items: center;
  width: 100%;
}

.avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-right: 24px;
  background-position: center;
  background-repeat: no-repeat;
  object-fit: cover;
}

.userInfo {
  width: 100%;
}

.userInfo h2 {
  font-family: Avenir;
  font-size: 42px;
  font-weight: 800;
  line-height: 57.37px;
  letter-spacing: -0.02em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000000;
  margin: 0;
  padding: 0;
  margin-bottom: 8px;
}

.userInfo p {
  font-family: Avenir;
  font-size: 18px;
  font-weight: 400;
  line-height: 24.59px;
  letter-spacing: -0.02em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #747E7F;
  margin: 0;
  padding: 0;
}

.emailAmdButton {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.profileLink {
  background: none;
  border: none;
  cursor: pointer;
  font-family: Avenir;
  font-size: 18px;
  font-weight: 400;
  line-height: 24.59px;
  letter-spacing: -0.02em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #7623FF;
  margin: 0;
  padding: 0;
}

.settingsGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
  grid-template-areas:
    "large-card small-card small-card small-card"
    "small-card small-card small-card large-card"
    "small-card small-card small-card small-card";

  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
    /* Для больших экранов два столбца */
    grid-template-areas:
      "large-card small-card"
      "large-card small-card"
      "small-card small-card";
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    /* Мобильная версия — одна колонка */
    grid-template-areas:
      "large-card"
      "small-card"
      "small-card"
      "small-card"
      "small-card";
  }
}

.settingCard {
  background-color: #ffffff;
  border-radius: 24px;
  border: 1px solid #E2E2E2;
  box-shadow: 0px 9px 10px 0px #0000001A;
  display: flex;
  flex-direction: column;
  padding: 24px;
}

.icon {
  margin-bottom: 32px;
  width: 48px;
  height: 48px;
}

.settingCard h3 {
  font-family: Avenir;
  font-size: 24px;
  font-weight: 800;
  line-height: 32.78px;
  letter-spacing: -0.02em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin: 0;
  padding: 0;
  color: #000000;
  margin-bottom: 8px;
}

.settingCard p {
  font-family: Avenir;
  font-size: 18px;
  font-weight: 400;
  line-height: 24.59px;
  letter-spacing: -0.02em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #747E7F;
  margin: 0;
  padding: 0;
}

.largeCard {
  grid-column: span 2;
}

.smallCard {
  grid-column: span 1;
}