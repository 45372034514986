.wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 80px;
}

.column {
    width: calc(50% - 12px);
    border: 1px solid #E2E2E2;
    background: #FFFFFF;
    padding: 36px;
    box-sizing: border-box;
}

.label {
    font-family: Avenir;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    letter-spacing: 0%;
    color: #000000;
    text-align: left;
    margin: 0;
    padding: 0;
    margin-bottom: 36px;
    height: 22px;
}

.link {
    text-decoration: none;
    font-family: Avenir;
    font-weight: 800;
    font-size: 18px;
    line-height: 25.5px;
    letter-spacing: -2%;
    text-align: left;
    color: #000000;
    margin: 0;
    padding: 0;
}

