.footer-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    font-family: Avenir;
    font-size: 16px;
    font-weight: 400;
    line-height: 21.86px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #747E7F;
    padding-left: 40px;
    padding-right: 40px;

    @media (max-width: 1220px) {
        padding-left: 22px;
        padding-right: 22px;
    }


    .footer-links {
        display: flex;
        gap: 54px;

        @media (max-width: 1220px) {
            justify-content: space-between;
            gap: 0;
            width: 100%;
        }

    }

    @media (max-width: 1220px) {

        .footer-link-visible {
            display: block;
        }

        span {
            display: none;
        }
    }
}

.link {
    color: #747E7F;
    text-decoration: none;

    @media (max-width: 1220px) {
        display: none;
    }

    &:hover {
        text-decoration: underline;
        opacity: 0.7;
    }
}

.white {
    color: #ffffff;
}

.mobile {
    display: none;

    @media (max-width: 1220px) {
        display: flex;
        flex-direction: column;
        background: #FAFBFC;
        min-height: 280px;
        width: 100%;
    }
}

.desktop {

    @media (max-width: 1220px) {
        display: none;
    }
}

.links {
    display: flex;
    flex-direction: column;
    min-height: 154px;
    padding: 20px;
    border-bottom: 1px solid #E2E2E2;
    box-sizing: border-box;
}

.linkMobile {
    font-family: Avenir;
    font-size: 16px;
    font-weight: 400;
    line-height: 21.86px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #747E7F;
    padding: 0;
    margin: 0;
    margin-bottom: 24px;
    text-decoration: none;

    &:last-child {
        margin-bottom: 0;
    }
}

.bottomMobile {
    height: 62px;
    border-top: 1px solid #E2E2E2;
    padding-left: 20px;
    box-sizing: border-box;
    align-items: center;
    display: flex;
}

.companyNameMobile {
    font-family: Avenir;
    font-size: 16px;
    font-weight: 400;
    line-height: 21.86px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #747E7F;
    margin: 0;
    padding: 0;
}