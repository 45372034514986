.title-block {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 24px;

    @media (max-width: 1020px) {
        margin-bottom: 0;
        align-items: center;
        height: 52px;
        padding-left: 16px;
        padding-right: 16px;
        box-sizing: border-box;
        margin-top: 60px;
    }
}

.title-block-right {
    display: flex;
    align-self: flex-end;

    @media (max-width: 1020px) {
        align-self: unset;
    }
}

.block-share {
    display: flex;
    align-items: center;
    height: 24px;
    margin-right: 36px;
    cursor: pointer;

    @media (max-width: 1020px) {
        margin-right: 16px;
    }
}

.share-icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    cursor: pointer;

    @media (max-width: 1020px) {
        margin-right: 0;
    }

    &:hover {
        opacity: 0.7;
    }
}

.share-text {
    margin: 0;
    padding: 0;
    font-family: Avenir;
    font-size: 16px;
    font-weight: 400;
    line-height: 21.86px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #747E7F;

    @media (max-width: 1020px) {
        display: none;
    }
}

.favorite-block {
    display: flex;
    align-items: center;
    height: 24px;
    cursor: pointer;
}

.favorite-icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    cursor: pointer;

    @media (max-width: 1020px) {
        margin-right: 0;
    }

    &:hover {
        opacity: 0.7;
    }
}

.favorite-text {
    margin: 0;
    padding: 0;
    font-family: Avenir;
    font-size: 16px;
    font-weight: 400;
    line-height: 21.86px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #747E7F;

    @media (max-width: 1020px) {
        display: none;
    }
}

.title-booking {
    margin: 0;
    padding: 0;
    font-family: Avenir;
    font-size: 42px;
    font-weight: 800;
    line-height: 57.37px;
    letter-spacing: -0.02em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #000000;

    @media (max-width: 1020px) {
        display: none;
    }
}

.buttonGoBack {
    height: 36px;
    display: none;
    align-items: center;
    background: #FFFFFF;
    border: 1px solid #E2E2E2;
    border-radius: 20px;
    margin: 0;
    padding: 0;
    cursor: pointer;

    @media (max-width: 1020px) {
        display: flex;
    }
}

.iconButtonGoBack {
    width: 20px;
    height: 20px;
    padding-left: 16px;
    margin-right: 4px;
}

.textInButtonGoBack {
    font-family: Avenir;
    font-weight: 400;
    font-size: 14px;
    line-height: 19.12px;
    letter-spacing: 0%;
    color: #000000;
    text-align: left;
    margin: 0;
    padding: 0;
    padding-right: 16px;
}