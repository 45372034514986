.modal {
    display: flex;
    margin-top: 59px;
    padding-left: 20px;
    padding-right: 20px;
    height: 345px;
    max-width: 100%;
    overflow-x: hidden;
    width: 100vw;
}

.content {
    background: #ffffff;
    box-shadow: 0px 25px 50px -12px #00000040;
    border-radius: 24px;
    position: relative;
    z-index: 10;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}

.top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 25px;
    margin-bottom: 16px;
}

.textTop {
    font-family: Avenir;
    font-size: 18px;
    font-weight: 800;
    line-height: 24.59px;
    letter-spacing: -0.02em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #000000;
    margin: 0;
    padding: 0;
}

.buttonClose {
    width: 24px;
    height: 24px;
    cursor: pointer;

    &:hover {
        opacity: 0.7;
    }
}

.destinationButton {
    background: #F1F2F2;
    border-radius: 16px;
    border: 1px solid #0000001A;
    height: 54px;
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
}

.iconButton {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    padding-left: 16px;
    object-fit: cover;
}

.textInButton {
    font-family: Avenir;
    font-weight: 400;
    font-size: 16px;
    line-height: 21.86px;
    letter-spacing: 0%;
    color: #747E7F;
    text-align: left;
    margin: 0;
    padding: 0;
}

.calendarButton {
    background: #F1F2F2;
    border-radius: 16px;
    border: 1px solid #0000001A;
    height: 54px;
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
}

.buttons {
    display: flex;
    height: 54px;
    width: 100%;
    margin-bottom: 16px;
}

.radiusButton {
    display: flex;
    justify-content: space-between;
    height: 54px;
    background: #F1F2F2;
    border-radius: 16px;
    margin: 0;
    padding: 0;
    margin-right: 16px;
    border: 1px solid #0000001A;
    -webkit-tap-highlight-color: transparent;
    width: calc(50% - 8px);
    box-sizing: border-box;
    cursor: pointer;
    align-items: center;
}

.texts {
    display: flex;
    flex-direction: column;
    padding-left: 16px;
}

.textTopInButton {
    margin: 0;
    padding: 0;
    font-family: Avenir;
    font-weight: 400;
    font-size: 12px;
    line-height: 16.39px;
    letter-spacing: 0%;
    color: #747E7F;
    text-align: left;
}

.textBottomInButton {
    font-family: Avenir;
    font-weight: 400;
    font-size: 16px;
    line-height: 21.86px;
    letter-spacing: 0%;
    color: #000000;
    margin: 0;
    padding: 0;
    text-align: left;
}

.arrowDownIcon {
    width: 20px;
    height: 20px;
    object-fit: cover;
    padding-right: 17px;
}

.guestsButton {
    display: flex;
    justify-content: space-between;
    height: 54px;
    background: #F1F2F2;
    border-radius: 16px;
    margin: 0;
    padding: 0;
    border: 1px solid #0000001A;
    -webkit-tap-highlight-color: transparent;
    width: calc(50% - 8px);
    box-sizing: border-box;
    cursor: pointer;
    align-items: center;
}

.findButton {
    width: 100%;
    background: #7623FF;
    border-radius: 16px;
    border: none;
    cursor: pointer;
    margin: 0;
    padding: 0;
    height: 54px;
    -webkit-tap-highlight-color: transparent;
    font-family: Avenir;
    font-weight: 400;
    font-size: 18px;
    line-height: 24.59px;
    letter-spacing: 0%;
    text-align: center;
    color: #ffffff;

    &:disabled {
        background: linear-gradient(0deg, rgba(118, 35, 255, 0.2), rgba(118, 35, 255, 0.2)),
            linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
    }
}

.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 4000;
    display: flex;
    justify-content: center;
    max-width: 100%;
    overflow-x: hidden;
    width: 100vw;
}