.host-details {
    display: flex;
    flex-direction: column;
    margin-bottom: 54px;

    @media (max-width: 1020px) {
        margin-bottom: 24px;
        padding-left: 20px;
        padding-right: 20px;
        width: 100vw;
        box-sizing: border-box;
    }

    .host-main {
        display: flex;
        margin-bottom: 24px;

        .host-avatar {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            object-fit: cover;
            margin-right: 16px;

            @media (max-width: 1020px) {
                width: 54px;
                height: 54px;
            }
        }

        .host-info {
            flex: 1;

            .host-name {
                margin: 0;
                padding: 0;
                margin-bottom: 8px;
                font-family: Avenir;
                font-size: 32px;
                font-weight: 800;
                line-height: 40px;
                letter-spacing: -0.02em;
                text-align: left;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;
                color: #000000;

                @media (max-width: 1020px) {
                    font-size: 18px;
                    line-height: 24.59px;
                    margin-bottom: 4px;
                }
            }

            .host-joined {
                margin: 0;
                padding: 0;
                font-family: Avenir;
                font-size: 18px;
                font-weight: 400;
                line-height: 24.59px;
                letter-spacing: -0.02em;
                text-align: left;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;
                margin-bottom: 8px;
                color: #000000;

                @media (max-width: 1020px) {
                    font-size: 16px;
                    line-height: 21.86px;
                    margin-bottom: 4px;
                }
            }

            .host-status {
                display: flex;

                .verify-block {
                    display: flex;
                    margin-right: 24px;
                    align-items: center;

                    .verify-icon {
                        width: 20px;
                        height: 20px;
                        margin-right: 4px;
                    }

                    .host-verified {
                        padding: 0;
                        margin: 0;
                        font-family: Avenir;
                        font-size: 18px;
                        font-weight: 400;
                        line-height: 24.59px;
                        letter-spacing: -0.02em;
                        text-align: left;
                        text-underline-position: from-font;
                        text-decoration-skip-ink: none;
                        color: #7623FF;

                        @media (max-width: 1020px) {
                            font-size: 16px;
                            line-height: 21.86px;
                        }
                    }
                }

                .reviews-block {
                    display: flex;
                    align-items: center;

                    .reviews-icon {
                        width: 20px;
                        height: 20px;
                        margin-right: 4px;
                    }

                    .host-reviews {
                        margin: 0;
                        padding: 0;
                        font-family: Avenir;
                        font-size: 18px;
                        font-weight: 400;
                        line-height: 24.59px;
                        letter-spacing: -0.02em;
                        text-align: left;
                        text-underline-position: from-font;
                        text-decoration-skip-ink: none;
                        color: #7623FF;

                        @media (max-width: 1020px) {
                            font-size: 16px;
                            line-height: 21.86px;
                        }
                    }
                }
            }
        }
    }
}

.contact-host {
    display: flex;
    flex-direction: column;
    width: 785px;
    margin-right: 24px;
    padding-left: 116px;

    @media (max-width: 1300px) {
        width: 100%;
        padding-left: 0;
        margin-right: 0;
        margin-bottom: 24px;
    }
}

.host-details-bottom {
    display: flex;

    @media (max-width: 1300px) {
        flex-direction: column;
    }

    .host-bio {
        margin: 0;
        padding: 0;
        margin-bottom: 24px;
        font-family: Avenir;
        font-size: 18px;
        font-weight: 400;
        line-height: 24.59px;
        letter-spacing: -0.02em;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #000000;

        @media (max-width: 1020px) {
            font-size: 16px;
            line-height: 21.86px;
        }
    }

    .contact-host-button {
        margin: 0;
        padding: 0;
        background: none;
        border: 1px solid #7623FF;
        color: #7623ff;
        border-radius: 16px;
        cursor: pointer;
        width: 785px;
        height: 54px;
        font-family: Avenir;
        font-size: 18px;
        font-weight: 400;
        line-height: 24.59px;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;

        @media (max-width: 1020px) {
            width: 100%;
            height: 41px;
        }


        &:hover {
            opacity: 0.7;
        }
    }
}

.host-additional-block {
    display: flex;
    flex-direction: column;

    .host-additional {
        display: flex;
        flex-direction: column;

        .host-additional-element {
            margin: 0;
            padding: 0;
            margin-bottom: 24px;
            font-family: Avenir;
            font-size: 18px;
            font-weight: 400;
            line-height: 24.59px;
            letter-spacing: -0.02em;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: #000000;

            @media (max-width: 1020px) {
                font-size: 16px;
                line-height: 21.86px;
                margin-bottom: 16px;
            }
        }
    }

    .host-warning {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 435px;
        height: 86px;
        border-radius: 16px;
        background: #F1F2F2;

        @media (max-width: 1020px) {
            width: 100%;
            height: 89px;
        }

        .warning-icon {
            width: 20px;
            height: 20px;
            margin-right: 10px;
            padding-left: 24px;

            @media (max-width: 1020px) {
                padding-left: 16px;
            }
        }

        .host-warning-text {
            margin: 0;
            padding: 0;
            font-family: Avenir;
            font-size: 14px;
            font-weight: 400;
            line-height: 19.12px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: #000000;
        }
    }
}