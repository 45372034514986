.paymentIconBlock {
    width: 72px;
    height: 52px;
    margin-right: 16px;
    background: #FFFFFF;
    border: 1px solid #E2E2E2;
    display: flex;
    align-items: center;
    justify-content: center;
}

.paymentList {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background: #ffffff;
    border: 1px solid #E2E2E2;
    border-radius: 16px;
    box-shadow: 0px 25px 50px -12px #00000040;
    z-index: 10;
}

.paymentGroup {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.paymentOption {
    display: flex;
    align-items: center;
    min-height: 84px;
    cursor: pointer;
    width: 100%;
    padding: 16px;
    box-sizing: border-box;

    &:hover {
        background: #F1F2F2;
    }
}

.selected {
    background: #F1F2F2;
}

.cardOption {
    display: flex;
    align-items: center;
    min-height: 84px;
    width: 100%;
    cursor: pointer;
    padding: 16px;
    box-sizing: border-box;

    &:hover {
        background: #f1f1f1;
    }
}

.addPaymentMethod {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.addOption {
    padding: 16px;
    cursor: pointer;
    width: 1005;
    box-sizing: border-box;
    display: flex;
    align-items: center;

    &:hover {
        background: #f1f1f1;
    }
}

.textAddPayment {
    padding: 16px;
    margin: 0;
    font-family: Avenir;
    font-weight: 800;
    font-size: 18px;
    line-height: 100%;
    letter-spacing: -2%;
    color: #000000;
    text-align: left;
}

.paymentIconvisa {
    width: 46px;
    height: 14.8px;
    object-fit: contain;

    @media (max-width: 1020px) {
        width: 29.19px;
        height: 18.05px;
    }
}

.paymentIconmastercard {
    width: 42.16px;
    height: 26.07px;
    object-fit: contain;

    @media (max-width: 1020px) {
        width: 29.19px;
        height: 18.05px;
    }
}

.paymentIconamex {
    width: 42.16px;
    height: 26.07px;
    object-fit: contain;

    @media (max-width: 1020px) {
        width: 29.19px;
        height: 18.05px;
    }
}

.paymentIconapplePay {
    width: 42.16px;
    height: 26.07px;
    object-fit: contain;

    @media (max-width: 1020px) {
        width: 29.19px;
        height: 18.05px;
    }
}

.paymentIcongooglePay {
    width: 44px;
    height: 17.6px;
    object-fit: contain;

    @media (max-width: 1020px) {
        width: 29.19px;
        height: 18.05px;
    }
}

.paymentIconpaypal {
    width: 56px;
    height: 14.9px;
    object-fit: contain;

    @media (max-width: 1020px) {
        width: 29.19px;
        height: 18.05px;
    }
}

.paymentIconDebet {
    width: 28.8px;
    height: 21.6px;

    @media (max-width: 1020px) {
        width: 29.19px;
        height: 18.05px;
    }
}

.methodName {
    font-family: Avenir;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    letter-spacing: 0%;
    margin: 0;
    padding: 0;
    color: #000000;
    text-align: left;
    width: 100%;
}

.textNumberCard {
    font-family: Avenir;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    letter-spacing: 0%;
    color: #000000;
    margin: 0;
    padding: 0;
}

.blockWithNumberCard {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.checkIcon {
    padding-right: 8px;
    width: 20px;
    height: 20px;
}