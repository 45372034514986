.content {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: #FAFBFC;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    overflow-x: hidden;
    width: 100vw;
}

.top {
    display: flex;
    width: 100%;
    justify-content: space-between;
    height: 60.38px;
    align-items: center;
    padding-left: 20px;
    padding-right: 18.19px;
    box-sizing: border-box;
}

.logo {
    width: 89.45px;
    height: 21.22px;
    object-fit: cover;
    cursor: pointer;

    &:hover {
        opacity: 0.7;
    }
}

.buttonClose {
    width: 24px;
    height: 24px;
    cursor: pointer;

    &:hover {
        opacity: 0.7;
    }
}

.loginBlock {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 64px;
    border-top: 1px solid #E2E2E2;
    border-bottom: 1px solid #E2E2E2;
    box-sizing: border-box;
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;

    &:hover {
        background: #F1F2F2;
    }
}

.userIcon {
    object-fit: cover;
    width: 24px;
    height: 24px;
}

.text {
    font-family: Avenir;
    font-size: 16px;
    font-weight: 400;
    line-height: 21.86px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #000000;
    margin: 0;
    padding: 0;
}

.links {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: 1px solid #E2E2E2;
    padding: 20px;
    box-sizing: border-box;
}

.link {
    text-decoration: none;
    font-family: Avenir;
    font-size: 16px;
    font-weight: 400;
    line-height: 21.86px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #000000;
    margin: 0;
    padding: 0;
    margin-bottom: 24px;

    &:last-child {
        margin-bottom: 0;
    }

    &:hover {
        background: #F1F2F2;
    }
}

.profileBlock {
    height: 88px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #E2E2E2;
    border-bottom: 1px solid #E2E2E2;
    box-sizing: border-box;
    padding-left: 18px;
    padding-right: 20px;
    cursor: pointer;

    &:hover {
        background: #F1F2F2;
    }
}

.profileInfo {
    display: flex;
    align-items: center;
}

.profileInfoBlock {
    display: flex;
    flex-direction: column;
}

.profileName {
    font-family: Avenir;
    font-size: 16px;
    font-weight: 800;
    line-height: 21.86px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #000000;
    margin: 0;
    padding: 0;
}

.profileEmail {
    font-family: Avenir;
    font-size: 16px;
    font-weight: 400;
    line-height: 21.86px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #747E7F;
    margin: 0;
    padding: 0;
}

.imageAvatar {
    width: 52px;
    height: 50px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 8px;
}

.buttonGoToProfile {
    height: 24px;
    width: 24px;
    border: none;
    background: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.iconChevron {
    width: 24px;
    height: 24px;
}