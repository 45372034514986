.card {
    width: 322px;
    min-height: 397px;
    border-radius: 24px;
    background: #FFFFFF;
    box-shadow: 0px 2px 3px 0px #0000001A;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;

    @media (max-width: 1020px) {
        width: 100%;
    }
}

.imageContainer {
    position: relative;
    width: 100%;
    height: 280px;

    .image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .rating {
        position: absolute;
        top: 16.5px;
        left: 16px;
        color: #ffffff;
        font-family: Avenir;
        font-size: 18px;
        font-weight: 400;
        line-height: 24.59px;
        letter-spacing: -0.02em;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        display: flex;
        z-index: 5;

        .starIcon {
            height: 24px;
            width: 24px;
            margin-right: 7px;
        }
    }

    .likeButton {
        position: absolute;
        top: 16px;
        right: 17px;
        background: none;
        border: none;
        cursor: pointer;
        height: 24px;
        width: 24px;

        .heartIcon {
            width: 24px;
            height: 24px;
            object-fit: cover;
        }

        &:hover {
            opacity: 0.7;
        }
    }

    .photoSlider {
        position: absolute;
        bottom: 0;
        left: 8px;
        display: flex;
        gap: 11px;

        .photoIndicator {
            width: 41.83px;
            height: 4px;
            border-radius: 20px;
            background: #ffffff;
            opacity: 0.25;
            cursor: pointer;
            transition: opacity 0.3s ease-in-out;

            &.active {
                opacity: 1;
            }
        }
    }
}

.content {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 14px;
    padding-right: 14px;
    display: flex;
    flex-direction: column;

    .header {
        display: flex;
        flex-direction: column;
        margin-bottom: 16px;

        h3 {
            font-family: Avenir;
            font-size: 18px;
            font-weight: 800;
            line-height: 24.59px;
            letter-spacing: -0.02em;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: #000000;
            margin: 0;
            padding: 0;
            margin-bottom: 8px;
            max-width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        p {
            font-family: Avenir;
            font-size: 14px;
            font-weight: 400;
            line-height: 19.12px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: #747E7F;
            margin: 0;
            padding: 0;
        }
    }

    .footer {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .price {
            font-family: Avenir;
            font-size: 18px;
            font-weight: 800;
            line-height: 24.59px;
            letter-spacing: -0.02em;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: #000000;
            margin: 0;
            padding: 0;
        }

        .night {
            font-family: Avenir;
            font-size: 14px;
            font-weight: 400;
            line-height: 19.12px;
            text-align: right;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: #747E7F;
            margin: 0;
            padding: 0;
        }
    }
}

.noImage {
    height: 284px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #E2E2E2;
    flex-direction: column;
}

.photoIndicatorBlock {
    padding-top: 7.5px;
    padding-bottom: 7.5px;
}

.textShowCost {
    margin: 0;
    padding: 0;
}

.deleteIcon {
    width: 18px;
    height: 18px;
}

.deleteButton {
    width: 25.5px;
    height: 25.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFFFFF4D;
    border: 1.13px solid #FFFFFF;
    border-radius: 18px;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
}

.iconLogo {
    width: 47px;
    height: 66px;
    object-fit: cover;
    object-position: center;
    padding-top: 8px;
  }
  
  .textNoPhoto {
    font-family: Avenir;
    font-weight: 800;
    font-size: 18px;
    line-height: 40px;
    letter-spacing: -2%;
    color: #CFCFCF;
    text-align: center;
    margin: 0;
    padding: 0;
  }