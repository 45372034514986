.modal {
    position: absolute;
    top: 54px;
    left: -1px;
    display: flex;
    align-items: center;
    z-index: 1000;

    @media (max-width:768px) {
        bottom: 0;
        left: 0;
        right: 0;
        position: fixed;
        width: 100vw;
        max-width: 100%;
        overflow-x: hidden;
        top: unset;
    }
}

.modalContent {
    display: flex;
    align-items: center;
    width: 343px;
    background: #FFFFFF;
    box-shadow: 0px 25px 50px -12px #00000040;
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;

    
    @media (max-width:768px) {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        width: 100%;
        max-height: calc(100vh - 100px);
        min-height: calc(100vh - 100px);
        flex-direction: column;
    }
}

.countryList {
    list-style: none;
    padding: 0;
    margin: 0;
    max-height: 307px;
    overflow-y: auto;
    width: 100%;
    display: flex;
    flex-direction: column;

    @media (max-width:768px) {
        max-height: calc(100vh - 221px);
    }

    // Стили для скроллбара
    ::-webkit-scrollbar {
        width: 4px;
        /* Ширина скроллбара */
    }

    ::-webkit-scrollbar-thumb {
        background-color: #B8BCBC;
        /* Цвет ползунка */
    }

    ::-webkit-scrollbar-track {
        background-color: #F1F2F2;
        /* Цвет трека (фон под ползунком) */
    }

    // Для Firefox
    scrollbar-width: thin;
    /* Уменьшенная ширина */
    scrollbar-color: #B8BCBC #F1F2F2;
    /* Цвет ползунка и трека */
}

.countryItem {
    display: flex;
    cursor: pointer;
    height: 51px;
    align-items: center;
    min-height: 51px;

    &:hover {
        background: #F1F2F2;
    }
}

.countryCodeModal {
    margin: 0;
    padding: 0;
    padding-left: 20px;
    margin-right: 8px;
    width: 40px;
    min-width: 40px;
    font-family: Avenir;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.12px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #000000;
}

.countryName {
    font-family: Avenir;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.12px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #000000;
    margin: 0;
    padding: 0;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.inputBlock {
    position: relative;
    display: flex;
    align-items: center;
    width: 343px;
    height: 54px;
    border-radius: 16px;
    background: #F1F2F2;
    border: 1px solid #0000001A;
    margin-bottom: 16px;
    padding-left: 16px;
    box-sizing: border-box;

    @media (max-width: 768px) {
        width: 280px;
    }
}

.icon {
    width: 20px;
    height: 20px;
}

.blockCountry {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 8px;
}

.countryText {
    font-family: Avenir;
    font-size: 12px;
    font-weight: 400;
    line-height: 16.39px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #747E7F;
    padding: 0;
    margin: 0;
}

.countryCode {
    font-family: Avenir;
    font-size: 16px;
    font-weight: 400;
    line-height: 21.86px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #000000;
    margin: 0;
    padding: 0;
}

.searchInput {
    margin: 0;
    padding: 0;
    font-family: Avenir;
    font-size: 16px;
    font-weight: 400;
    line-height: 21.86px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #000000;
    width: 100%;
    background: none;
    border: none;

    &:focus {
        outline: none;
    }
}

.arrowDownIcon {
    width: 20px;
    height: 20px;
    padding-right: 17px;
    padding-left: 8px;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
}

.checkIcon {
    width: 20px;
    height: 20px;
    padding-right: 10px;
}

.mobile {
    display: none;

    @media (max-width:768px) {
        display: flex;
        width: 100%;
    }
}

.top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 65px;
    padding-left: 20px;
    padding-right: 20px;
}

.textTop {
    font-family: Avenir;
    font-size: 18px;
    font-weight: 800;
    line-height: 24.59px;
    letter-spacing: -0.02em;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #000000;
    margin: 0;
    padding: 0;
}

.buttonClose {
    width: 24px;
    height: 24px;
    cursor: pointer;

    &:hover {
        opacity: 0.7;
    }
}

.searchBlock {
    display: flex;
    width: 100%;
    height: 56px;
    align-items: center;
    background: #F1F2F2;

    img {
        width: 24px;
        height: 24px;
        padding-left: 20px;
        margin-right: 4px;
    }
}

.textSearch {
    font-family: Avenir;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.12px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin: 0;
    padding: 0;
    color: #747E7F;
    width: 100%;
    height: 100%;
    border: none;
    background: none;
    outline: none;

    &:focus {
        outline: none;
        border: none;
    }
}