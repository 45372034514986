.content {
    display: flex;
    flex-direction: column;
    width: 960px;
    margin: 0 auto;

    @media (max-width: 1020px) {
        width: 100%;
        margin: 0;
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
    }
}

.titleArticle {
    margin: 0 auto;
    margin-bottom: 24px;
    padding: 0;
    padding-top: 32px;
    font-family: Avenir;
    font-weight: 800;
    font-size: 42px;
    line-height: 100%;
    letter-spacing: -2%;
    vertical-align: middle;
    color: #000000;
    max-width: 794px;
    text-align: left
}

.subtitle {
    margin: 0 auto;
    padding: 0;
    margin-bottom: 24px;
    max-width: 794px;
    font-family: Avenir;
    font-weight: 800;
    font-size: 24px;
    line-height: 100%;
    letter-spacing: -2%;
    color: #000000;
    text-align: left;
    width: 100%;
}

.authorBlock {
    display: flex;
    height: 40px;
    align-items: center;
    width: 794px;
    margin: 0 auto;
    margin-bottom: 36px;
}

.authorIcon {
    width: 40px;
    height: 40px;
    margin-right: 16px;
    object-fit: contain;
}

.authorDetailsText {
    margin: 0;
    padding: 0;
    font-family: Avenir;
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0%;
    color: #000000;
    text-align: left;
}

.section {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 794px;
    max-width: 794px;
    margin-bottom: 36px;

    @media (max-width: 1020px) {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        max-width: 100%;
    }
}

.paragraphText {
    font-family: Avenir;
    font-weight: 400;
    font-size: 18px;
    line-height: 100%;
    letter-spacing: -2%;
    color: #000000;
    margin: 0;
    padding: 0;
    text-align: left;
    margin-bottom: 24px;
    width: 100%;
}

.underlinText {
    text-decoration: underline;
    text-decoration-style: solid;
    text-decoration-thickness: 0%;
    cursor: pointer;
}

.title {
    font-family: Avenir;
    font-weight: 800;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -2%;
    color: #000000;
    text-align: left;
    width: 100%;
    margin: 0;
    padding: 0;
    margin-bottom: 24px;
}

.mb-0 {
    margin-bottom: 0;
}

.imageTextBlock {
    width: 100%;
    display: flex;
    align-items: center;
    height: 240px;
    margin-bottom: 24px;

    @media (max-width: 1020px) {
        flex-direction: column;
        height: auto;
    }

    img {
        width: 379px;
        height: 240px;
        margin-right: 36px;
        object-fit: contain;

        @media (max-width: 1020px) {
            margin-bottom: 24px;
        }
    }
}

.options {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 20px;
    margin-bottom: 24px;
}

.option {
    font-family: Avenir;
    font-weight: 400;
    font-size: 18px;
    line-height: 100%;
    letter-spacing: -2%;
    color: #000000;
    margin: 0;
    padding: 0;
    text-align: left;
}

.imageInSection {
    width: 794px;
    height: 503px;
    margin-bottom: 24px;
    object-fit: contain;
}

.twoBlocks {
    display: flex;
    width: 100%;

    @media (max-width: 1020px) {
        flex-direction: column;
    }
}

.widthTwoBlocks {
    width: calc(50% - 12px);
    margin-right: 24px;

    &:last-child {
        margin-right: 0;
    }

    @media (max-width: 1020px) {
        margin-right: 0;
        width: 100%;
    }
}

.border {
    border: 3px solid #3AA600;
    width: 86px;

    @media (max-width: 1020px) {
        border: none;
    }
}

.blockForImage {
    width: 100%;
    position: relative;
    height: 608px;
    margin-bottom: 36px;
}

.imageBig {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.blockTextAbsolute {
    position: absolute;
    right: 0;
    bottom: 0;
    background: #FFFFFF;
    padding: 24px;
    display: flex;
    flex-direction: column;
}

.maxWidth316 {
    width: 316px;
    max-width: 316px;

    @media (max-width: 1020px) {
        width: 250px;
        max-width: 250px;
    }
}

.titleAbsoluteText {
    font-family: Avenir;
    font-weight: 800;
    font-size: 24px;
    line-height: 100%;
    letter-spacing: -2%;
    text-decoration: underline;
    text-decoration-style: solid;
    text-decoration-thickness: 0%;
    color: #000000;
    text-align: left;
    margin: 0;
    padding: 0;
    margin-bottom: 16px;
    max-width: 316px;
    width: 316px;

    @media (max-width: 1020px) {
        width: 250px;
        max-width: 250px;
    }
}