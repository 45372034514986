.photoGalleryModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3200;
  display: flex;
  align-items: center;
  justify-content: center;

  .closeBtn {
    position: absolute;
    top: 21px;
    right: 23px;
    cursor: pointer;
    z-index: 10;
    height: 54px;
    width: 54px;
    background: #FFFFFF4D;
    border: 1.5px solid #FFFFFF;
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 1020px) {
      top: 17px;
      right: 15px;
    }
  }

  .iconButtonclose {
    width: 24px;
    height: 24px;
  }

  .arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    z-index: 10;
    width: 54px;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
    background: #FFFFFF4D;
    border: 1.5px solid #FFFFFF;

    &.leftArrow {
      left: 41px;
    }

    &.rightArrow {
      right: 41px;
    }

    &.visible {
      opacity: 1;
    }
  }

  .iconArrow {
    width: 24px;
    height: 24px;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000000;
  }

  .photoGalleryContent {
    position: relative;
    z-index: 1010;
    width: 828px;
    height: calc(100vh - 146px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media (max-width: 1020px) {
      width: 100%;
      height: 33%;
    }

    .loader {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      font-size: 16px;
    }

    .photoContainer {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .photo {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;

        &.visible {
          opacity: 1;
        }
      }
    }
  }
}

.photoCounter {
  position: absolute;
  bottom: 22px;
  color: white;
  font-family: Avenir;
  font-weight: 400;
  font-size: 18px;
  line-height: 24.59px;
  letter-spacing: -2%;
}