.pollWrapper {
    display: flex;
    border: 1px solid #E2E2E2;
    padding: 36px;
    box-sizing: border-box;
    width: 100%;
    background: #FFFFFF;
    margin-bottom: 36px;
}

.left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 36px;
    width: calc(50% - 54px);
    min-height: 143px;
}

.right {
    width: calc(50% - 54px);
    display: flex;
    flex-direction: column;
}

.question {
    font-family: Avenir;
    font-weight: 800;
    font-size: 24px;
    line-height: 100%;
    letter-spacing: -2%;
    color: #000000;
    text-align: left;
    margin: 0;
    padding: 0;
}

.voted {
    margin: 0;
    padding: 0;
    height: 36px;
    padding-top: 14px;
    font-family: Avenir;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    letter-spacing: 0%;
    color: #2E2E2E;
    text-align: left;
    box-sizing: border-box;

    span {
        color: #7623FF;
        margin: 0;
        padding: 0;
    }
}

.optionRow {
    display: flex;
    margin-bottom: 24px;
    flex-direction: column;
}

.radioText {
    display: flex;
    align-items: center;
    gap: 6px;
    width: 100%;
}

.progressBar {
    width: 316px;
    display: flex;
    align-items: center;
    border: 3px solid #BDBDBD;
    position: relative;
}

.fill {
    border: 3px solid #7623FF;
    position: absolute;
}

.percent {
    text-align: right;
    font-family: Avenir;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    letter-spacing: 0%;
    margin: 0;
    padding: 0;
    color: #000000;
}

.optionRowTop {
    display: flex;
    width: 100%;
    height: 22px;
    align-items: center;
    margin-bottom: 12px;
}

.spanText {
    font-family: Avenir;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    letter-spacing: 0%;
    color: #000000;
    margin: 0;
    padding: 0;
    text-align: left;
    margin-left: 8px;
}

.radioOuter {
    width: 18px;
    height: 18px;
    border-radius: 18px;
    border: 1px solid #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  
  .radioInner {
    width: 8px;
    height: 8px;
    border-radius: 10px;
    background: #7623FF;
  }