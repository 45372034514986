.room-gallery-wrapper {
    width: 897px;
    margin-bottom: 32px;
    border-top: 1px solid #E2E2E2;

    @media (max-width: 1020px) {
        width: 100vw;
        margin-bottom: 24px;
    }
}

.room-gallery-heading {
    margin: 0;
    padding: 0;
    padding-top: 32px;
    margin-bottom: 32px;
    font-family: Avenir;
    font-size: 32px;
    font-weight: 800;
    line-height: 40px;
    letter-spacing: -0.02em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #000000;

    @media (max-width: 1020px) {
        font-size: 24px;
        line-height: 32.78px;
        padding-top: 24px;
        margin-bottom: 16px;
        padding-left: 20px;
        padding-right: 20px;
    }
}

.room-gallery {
    position: relative;
    outline: none;

    @media (max-width: 1020px) {
        padding-left: 20px;
    }

    .room-gallery-item {
        width: 436.5px !important;
        outline: none !important;

        @media (max-width: 1020px) {
            width: calc(((100vw - 20px - 24px)/11)*10) !important;
        }
    }
}


.room-gallery-image {
    width: 100%;
    height: 400px;
    border-radius: 16px;
    object-fit: cover;
    cursor: pointer;

    @media (max-width: 1020px) {
        height: 312px;
    }
}

.room-gallery-info {
    text-align: left;
}

.room-gallery-title {
    margin: 0;
    padding: 0;
    margin-top: 16px;
    margin-bottom: 8px;
    font-family: Avenir;
    font-size: 24px;
    font-weight: 800;
    line-height: 32.78px;
    letter-spacing: -0.02em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #000000;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (max-width: 1020px) {
        font-size: 18px;
        line-height: 24.59px;
    }
}

.room-gallery-subtitle {
    margin: 0;
    bottom: 0;
    font-family: Avenir;
    font-size: 16px;
    font-weight: 400;
    line-height: 21.86px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #747E7F;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}