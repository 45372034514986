.rightContent {
    display: flex;
    flex-direction: column;
    position: sticky;
    width: 553px;
    height: 440px;
    top: 108px;
    padding: 24px;
    box-sizing: border-box;
    border: 1px solid #E2E2E2;
    box-shadow: 0px 9px 10px 0px #0000001A;
    background: #FFFFFF;

    @media (max-width: 1439px) {
        width: 100%;
        height: auto;
        position: static;
        border: none;
        box-shadow: none;
        background: none;
        padding-left: 0;
        padding-right: 0;
        padding-top: 20px;
        padding-bottom: 0;
        margin-bottom: 24px;
    }
}

.topRightContent {
    width: 100%;
    display: flex;
    height: 140px;
    margin-bottom: 24px;
    align-items: center;

    @media (max-width: 1439px) {
        height: auto;
        margin-bottom: 0;
    }
}

.noImage {
    width: 140px;
    height: 140px;
    border-radius: 16px;
    margin-right: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #E2E2E2;
    flex-direction: column;
    min-width: 140px;

    @media (max-width: 1439px) {
        width: 90px;
        height: 90px;
        margin-right: 16px;
        min-width: 90px;
    }
}

.imageBooking {
    width: 140px;
    height: 140px;
    border-radius: 16px;
    margin-right: 24px;
    object-fit: contain;
    cursor: pointer;

    @media (max-width: 1439px) {
        width: 90px;
        height: 90px;
        margin-right: 16px;
    }
}

.iconLogo {
    width: 47px;
    height: 66px;
    object-fit: cover;
    object-position: center;
    padding-top: 8px;

    @media (max-width: 1439px) {
        padding-top: 0;
    }
}

.textNoPhoto {
    font-family: Avenir;
    font-weight: 800;
    font-size: 18px;
    line-height: 40px;
    letter-spacing: -2%;
    color: #CFCFCF;
    text-align: center;
    margin: 0;
    padding: 0;

    @media (max-width: 1439px) {
        display: none;
    }
}

.bookingBlock {
    width: 100%;
    padding-top: 24px;
    padding-bottom: 24px;
    display: flex;
    flex-direction: column;

    @media (max-width: 1439px) {
        padding-top: 0;
        padding-bottom: 0;
    }
}

.textEntire {
    font-family: Avenir;
    font-weight: 400;
    font-size: 16px;
    line-height: 21.86px;
    letter-spacing: 0%;
    color: #747E7F;
    margin: 0;
    padding: 0;
    text-align: left;
    margin-bottom: 4px;

    @media (max-width: 1439px) {
        font-size: 14px;
        line-height: 100%;
    }
}

.nameApartament {
    font-family: Avenir;
    font-weight: 800;
    font-size: 18px;
    line-height: 24.59px;
    letter-spacing: -2%;
    color: #000000;
    margin: 0;
    padding: 0;
    margin-bottom: 4px;
    text-align: left;
    height: 50px;
    max-height: 50px;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (max-width: 1439px) {
        font-size: 16px;
        line-height: 100%;
        height: 44px;
        max-height: 44px;
    }
}

.ratingBlock {
    display: flex;
    align-items: center;
    height: 20px;
}

.ratingIcon {
    width: 20px;
    height: 20px;
    margin-right: 2px;
    object-fit: contain;
}

.ratingNumberText {
    font-family: Avenir;
    font-weight: 400;
    font-size: 14px;
    line-height: 19.12px;
    letter-spacing: 0%;
    color: #7623FF;
    margin: 0;
    padding: 0;
    margin-right: 8px;
    text-align: left;
}

.reviewsText {
    font-family: Avenir;
    font-weight: 400;
    font-size: 14px;
    line-height: 19.12px;
    letter-spacing: 0%;
    color: #000000;
    margin: 0;
    padding: 0;
    text-align: left;
}

.priceDetails {
    font-family: Avenir;
    font-weight: 800;
    font-size: 24px;
    line-height: 32.78px;
    letter-spacing: -2%;
    color: #000000;
    margin: 0;
    padding: 0;
    text-align: left;
    padding-top: 24px;
    margin-bottom: 24px;
    border-top: 1px solid #E2E2E2;
    width: 100%;
}

.blockSpace {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 24px;
}

.textLeftNights {
    font-family: Avenir;
    font-weight: 400;
    font-size: 18px;
    line-height: 24.59px;
    letter-spacing: -2%;
    color: #747E7F;
    margin: 0;
    padding: 0;
    text-align: left;
}

.textRightNights {
    font-family: Avenir;
    font-weight: 400;
    font-size: 18px;
    line-height: 24.59px;
    letter-spacing: -2%;
    color: #747E7F;
    margin: 0;
    padding: 0;
    text-align: right;
}

.totalBlock {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-top: 24px;
    border-top: 1px solid #E2E2E2;
}

.totalText {
    font-family: Avenir;
    font-weight: 400;
    font-size: 18px;
    line-height: 24.59px;
    letter-spacing: -2%;
    color: #000000;
    margin: 0;
    padding: 0;
    text-align: left;
}

.priceTotalText {
    font-family: Avenir;
    font-weight: 400;
    font-size: 18px;
    line-height: 24.59px;
    letter-spacing: -2%;
    color: #000000;
    margin: 0;
    padding: 0;
    text-align: right;
}