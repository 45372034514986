.header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 24px;
    padding-bottom: 24px;
    z-index: 2000;
    height: 102px;
    box-sizing: border-box;
    max-width: 1440px;

    &.sticky {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        animation: slideDown 0.3s ease-in-out;
        padding-left: 40px;
        padding-right: 40px;
        background: #ffffff;
        margin: 0 auto;
    }

    .header-content {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .header-left {
        display: flex;
        align-items: center;
        background: #fff;
    }

    @keyframes slideDown {
        from {
            transform: translateY(-100%);
        }

        to {
            transform: translateY(0);
        }
    }
}

.leftHeaderIcon {
    width: 118px;
    height: 28px;
    cursor: pointer;
    object-fit: contain;
}

.logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 134px;
    height: 54px;
}

.relative {
    position: relative;
}

.sticky-navigation {
    display: flex;
    align-items: center;

    nav {
        display: flex;
        margin-left: 36px;
        gap: 24px;

        button {
            margin: 0;
            padding: 0;
            background: none;
            border: none;
            font-family: Avenir;
            font-size: 18px;
            font-weight: 400;
            line-height: 24.59px;
            letter-spacing: -0.02em;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: #000000;
            text-decoration: none;
            cursor: pointer;

            &:hover {
                opacity: 0.7;
            }
        }
    }
}

.header-right-sticky {
    display: flex;
    align-items: center;

    .price-block {
        display: flex;
        align-items: center;

        .price {
            padding: 0;
            margin: 0;
            margin-right: 16px;
            font-family: Avenir;
            font-size: 24px;
            font-weight: 800;
            line-height: 32.78px;
            letter-spacing: -0.02em;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: #000000;
            width: 100%;
        }

        .header-reviews {
            padding: 0;
            margin: 0;
            margin-right: 32px;
            font-family: Avenir;
            font-size: 18px;
            font-weight: 400;
            line-height: 24.59px;
            letter-spacing: -0.02em;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: #747E7F;
            width: 100%;
        }

        .check-dates-btn {
            height: 54px;
            padding: 0;
            margin: 0;
            background-color: #7623FF;
            border: none;
            border-radius: 16px;
            cursor: pointer;
            justify-content: center;


            .textInButton {
                font-family: Avenir;
                font-size: 18px;
                font-weight: 400;
                line-height: 24.59px;
                text-align: center;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;
                color: white;
                margin: 0;
                padding: 0;
                padding-left: 24px;
                padding-right: 24px;
            }

            &:hover {
                opacity: 0.7;
            }
        }
    }
}