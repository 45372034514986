.footer {
    width: 100%;

    .footer-top {
        width: 100%;
        padding-left: 40px;
        padding-right: 40px;
        box-sizing: border-box;

        @media (max-width: 1220px) {
            padding-left: 20px;
            padding-right: 20px;
            box-sizing: border-box;
        }

        .footerContent {
            width: 100%;
            padding-top: 32px;
            border-top: 1px solid #E2E2E2;

            @media (max-width: 1220px) {
                padding-top: 24px;
                margin-bottom: 24px;
            }
        }

        .footer-heading {
            padding: 0;
            margin: 0;
            margin-bottom: 32px;
            font-family: Avenir;
            font-size: 32px;
            font-weight: 800;
            line-height: 40px;
            letter-spacing: -0.02em;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: #000000;

            @media (max-width: 1220px) {
                font-size: 24px;
                line-height: 32.78px;
                margin-bottom: 24px;
            }
        }

        .footer-columns {
            display: flex;
            justify-content: space-between;
            gap: 24px;

            @media (max-width: 1220px) {
                justify-content: unset;
                flex-direction: column;
            }

            .footer-column {
                flex: 1;
                max-width: 437px;

                @media (max-width: 1220px) {
                    max-width: 100%;
                }

                .footer-column-heading {
                    padding: 0;
                    margin: 0;
                    margin-bottom: 16px;
                    font-family: Avenir;
                    font-size: 24px;
                    font-weight: 800;
                    line-height: 32.78px;
                    letter-spacing: -0.02em;
                    text-align: left;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;
                    color: #000000;

                    @media (max-width: 1220px) {
                        font-size: 18px;
                        line-height: 24.59px;
                        margin-bottom: 8px;
                    }
                }

                ul {
                    list-style: none;
                    margin: 0;
                    padding: 0;

                    li {
                        margin: 0;
                        padding: 0;
                        margin-bottom: 16px;
                        font-family: Avenir;
                        font-size: 18px;
                        font-weight: 400;
                        line-height: 24.59px;
                        letter-spacing: -0.02em;
                        text-align: left;
                        text-underline-position: from-font;
                        text-decoration-skip-ink: none;
                        color: #000000;

                        @media (max-width: 1220px) {
                            font-size: 16px;
                            line-height: 21.86px;
                            margin-bottom: 8px;
                        }

                        &:last-child {
                            margin-bottom: 0;
                        }

                        .footer-button {
                            background: none;
                            border: none;
                            margin: 0;
                            padding: 0;
                            color: #7623ff;
                            text-decoration: none;
                            cursor: pointer;
                            font-family: Avenir;
                            font-size: 18px;
                            font-weight: 400;
                            line-height: 24.59px;
                            letter-spacing: -0.02em;
                            text-align: left;
                            text-underline-position: from-font;
                            text-decoration-skip-ink: none;

                            @media (max-width: 1220px) {
                                font-size: 16px;
                                line-height: 21.86px;
                            }

                            &:hover {
                                opacity: 0.7;
                            }
                        }
                    }
                }
            }
        }
    }
}