.top {
    height: 52px;
    width: 100%;
    padding-left: 40px;
    display: flex;
    align-items: center;
    margin-top: -54px;
    box-sizing: border-box;

    @media (max-width: 1020px) {
        margin-top: 0;
        padding-left: 20px;
    }
}

.buttonGoBack {
    height: 36px;
    display: flex;
    align-items: center;
    background: #FFFFFF;
    border: 1px solid #E2E2E2;
    border-radius: 20px;
    margin: 0;
    padding: 0;
    cursor: pointer;
}

.iconButtonGoBack {
    width: 20px;
    height: 20px;
    padding-left: 16px;
    margin-right: 4px;
}

.textInButtonGoBack {
    font-family: Avenir;
    font-weight: 400;
    font-size: 14px;
    line-height: 19.12px;
    letter-spacing: 0%;
    color: #000000;
    text-align: left;
    margin: 0;
    padding: 0;
    padding-right: 16px;
}

.blockTitle {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
    height: 58px;
    box-sizing: border-box;

    @media (max-width: 1020px) {
        padding-right: 20px;
        padding-left: 20px;
    }
}

.textTitle {
    font-family: Avenir;
    font-weight: 800;
    font-size: 42px;
    line-height: 57.37px;
    letter-spacing: -2%;
    color: #000000;
    text-align: left;
    margin: 0;
    padding: 0;
}

.editBlock {
    display: flex;
    height: 24px;
    align-self: flex-end;
    cursor: pointer;
}

.iconEdit {
    width: 24px;
    height: 24px;
    margin-right: 8px;
}

.editText {
    font-family: Avenir;
    font-weight: 400;
    font-size: 16px;
    line-height: 21.86px;
    letter-spacing: 0%;
    color: #000000;
    text-align: right;
    margin: 0;
    padding: 0;
}

.blockContent {
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
    box-sizing: border-box;

    @media (max-width: 1020px) {
        padding-right: 20px;
        padding-left: 20px;
    }
}

.subtitleDate {
    font-family: Avenir;
    font-weight: 400;
    font-size: 16px;
    line-height: 21.86px;
    letter-spacing: 0%;
    color: #747E7F;
    text-align: left;
    margin: 0;
    padding: 0;
    padding-top: 24px;
    margin-bottom: 24px;
}

.blockCards {
    margin-bottom: 24px;
}

.page {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}