.contentMobileCalendar {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: #fafbfc;
    display: flex;
    flex-direction: column;
    z-index: 4000;
    max-width: 100%;
    overflow-x: hidden;
    width: 100vw;
    
    /* Глобальные стили для DayPicker */
    :global .rdp {
        width: 100%;
        max-width: none;
    }

    :global .rdp-root {
        margin-right: -12px;
        --rdp-accent-color: #7623FF;
        /* Цвет акцента */
        --rdp-accent-background-color: #EDE5FC;
        /* Цвет фона акцента */
    }

    :global .rdp-month {
        width: 100%;
    }

    :global .rdp-months {
        max-width: 100%;
        padding: 0;
    }

    :global .rdp-month_grid {
        width: 100%;
    }

    :global .rdp-head_row {
        display: none;
        /* Убираем стандартный заголовок дней недели */
    }

    :global .rdp-table {
        width: 100%;
        table-layout: fixed;
        /* Равномерное распределение ячеек */
        border-spacing: 0;
    }

    :global .rdp-day {
        width: 38.73px;
        height: 38.73px;
    }

    :global .rdp-day_selected {
        background-color: #7623ff;
        color: #fff;
    }

    :global .rdp-weekdays {
        display: none;
    }

    :global .rdp-nav {
        display: none;
    }

    :global .rdp-day_button {
        margin: 0 auto;
        font-family: Avenir;
        font-size: 12px;
        font-weight: 400;
        line-height: 16.39px;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #2E2E2E;
    }

    :global .rdp-range_start .rdp-day_button {
        color: #ffffff;
    }

    :global .rdp-range_end .rdp-day_button {
        color: #ffffff;
    }

    :global .rdp-day_disabled .rdp-day_button {
        color: #BEBEBE;
    }

    :global .rdp-day_disabled {
        color: #BEBEBE;
    }

    /* Изменение шрифта для заголовка месяца */
    :global .rdp-month_caption {
        font-family: Avenir;
        font-size: 18px;
        font-weight: 800;
        line-height: 24.59px;
        letter-spacing: -0.02em;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #2E2E2E;
        margin: 0;
        padding: 0;
    }

}

.calendarContainer {
    flex: 1;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;


    // Стили для скроллбара
    ::-webkit-scrollbar {
        width: 4px;
        /* Ширина скроллбара */
    }

    ::-webkit-scrollbar-thumb {
        background-color: #B8BCBC;
        /* Цвет ползунка */
    }

    ::-webkit-scrollbar-track {
        background-color: #F1F2F2;
        /* Цвет трека (фон под ползунком) */
    }

    // Для Firefox
    scrollbar-width: thin;
    /* Уменьшенная ширина */
    scrollbar-color: #B8BCBC #F1F2F2;
    /* Цвет ползунка и трека */
}

.monthsContainer {
    display: flex;
    flex-direction: column;
}

.monthContainer {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 16px;
    padding-left: 20px;
}


.weekdaysHeader {
    position: sticky;
    top: 0;
    z-index: 10;
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
}

.weekdaysHeader th {
    text-align: center;
    font-family: Avenir;
    font-size: 12px;
    font-weight: 400;
    line-height: 16.39px;
    color: #888888;
    padding: 8px 0;
    width: 14.28%;
    /* Равное распределение на 7 дней */
}

.top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 65px;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
}

.textTop {
    font-family: Avenir;
    font-size: 18px;
    font-weight: 800;
    line-height: 24.59px;
    letter-spacing: -0.02em;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #000000;
    margin: 0;
    padding: 0;
}

.buttonClose {
    width: 24px;
    height: 24px;
    cursor: pointer;

    &:hover {
        opacity: 0.7;
    }
}

.bottom {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 121px;
    background: #FFFFFF;
    border-top: 1px solid #E2E2E2;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
}

.textBottom {
    font-family: Avenir;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.12px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #000000;
    margin: 0;
    padding: 0;
    padding-top: 16px;
    margin-bottom: 16px;
}

.buttonDone {
    width: 100%;
    height: 54px;
    background: #7623FF;
    border: none;
    cursor: pointer;
    font-family: Avenir;
    font-size: 18px;
    font-weight: 400;
    line-height: 24.59px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #ffffff;

    &:hover {
        opacity: 0.7;
    }

}

.weekDaysBlock {
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    box-sizing: border-box;
    border-bottom: 1px solid #e2e2e2;
    background-color: #fafbfc;
}