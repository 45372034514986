.mapContainer {
  position: relative;
  width: 100%;
  min-height: 747px;
}

.mapButton {
  position: absolute;
  top: 10px;
  left: 10px;
  background: #FFFFFF;
  box-shadow: 0px 22px 44px 0px #00000040;
  box-shadow: 0px 4px 4px 0px #00000040;
  border: none;
  cursor: pointer;
  z-index: 1000;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    display: none;
  }

  img {
    width: 20px;
    height: 20px;
    object-fit: cover;
  }
}

.listButton {
  position: absolute;
  top: 10px;
  left: 10px;
  background: #FFFFFF;
  box-shadow: 0px 0px 1px -1px #00000066;
  box-shadow: 0px 4px 6px -1px #00000026;
  border: none;
  cursor: pointer;
  z-index: 1000;
  height: 39px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 8px;
  padding-right: 8px;

  @media (max-width: 768px) {
    display: none;
  }

  img {
    width: 20px;
    height: 20px;
    object-fit: cover;
  }
}

.text {
  font-family: Roboto;
  font-size: 20px;
  font-weight: 400;
  line-height: 23.44px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #262626;
  margin: 0;
  padding: 0;
  margin-left: 12px;
}

.modal {
  box-shadow: 0px 22px 44px 0px #00000040;
  box-shadow: 0px 4px 4px 0px #00000040;

}

.mapContenOnly {
  width: 100%;
  height: 747px;
  border-radius: 24px;
}

.mapContetnAndList {
  width: 100%;
  height: 100%;
  border-radius: 24px;
}

.mobilePopup {
  position: fixed;
  bottom: 7px;
  left: 6px;
  right: 6px;
  z-index: 2000;
}