.mobileTitle {
    font-family: Avenir;
    font-weight: 800;
    font-size: 24px;
    line-height: 32.78px;
    letter-spacing: -2%;
    text-align: left;
    color: #000000;
    margin: 0;
    padding: 0;
    margin-bottom: 24px;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    display: none;
    width: 100%;
    box-sizing: border-box;

    @media (max-width: 1020px) {
        display: flex;
    }
}