.dropdown {
    position: absolute;
    top: 60px;
    right: 0;
    z-index: 100;
    width: 285px;
    border-radius: 16px;
    box-shadow: 0px 25px 50px -12px #00000040;
    background: #ffffff;

    .menu {
        list-style: none;
        padding: 0;
        margin: 0;
        width: 100%;

        .menuItem {
            display: flex;
            align-items: center;
            cursor: pointer;
            height: 54px;
            width: 100%;
            font-family: Avenir;
            font-size: 16px;
            font-weight: 400;
            line-height: 21.86px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: #000000;
            margin: 0;
            padding: 0;
            box-sizing: border-box;

            &:hover {
                background: #F1F2F2;
            }

            /* Округления для первого элемента */
            &:first-child:hover {
                border-top-left-radius: 16px;
                border-top-right-radius: 16px;
            }

            /* Округления для последнего элемента */
            &:last-child:hover {
                border-bottom-left-radius: 16px;
                border-bottom-right-radius: 16px;
            }

            .icon {
                margin-right: 8px;
                width: 20px;
                height: 20px;
                padding-left: 20px;
            }
        }

        .pl-20 {
            padding-left: 20px;
        }
    }
}

.brTop {
    border-top: 1px solid #E2E2E2;
}