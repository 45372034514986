.top {
  display: flex;
  width: 897px;
  height: 496px;
  margin-bottom: 32px;

  @media (max-width: 1020px) {
    width: 100vw;
    margin-bottom: 0;
    height: 250px;
  }
}

.desktop {
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 24px;
  overflow: hidden;

  @media (max-width: 1020px) {
    display: none;
  }
}

.firstColumn {
  width: 444.5px;
  height: 100%;
  margin-right: 8px;

  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    cursor: pointer;
  }
}

.secondColumn {
  display: flex;
  flex-direction: column;
  width: 218.25px;
  margin-right: 8px;
}

.secondImageBlock {
  width: 100%;
  height: 244px;
  margin-bottom: 8px;

  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    cursor: pointer;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.threeColumn {
  width: 218.43px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.noImage {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #E2E2E2;
  flex-direction: column;
}

.relative {
  position: relative;
}

.showAllPhotosBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 199px;
  height: 88px;
  background: #FAFBFCCC;
  border-radius: 15px;
  backdrop-filter: blur(12.300000190734863px);
  top: 149px;
  left: 8.5px;
  cursor: pointer;
}

.quantityPhotos {
  font-family: Avenir;
  font-size: 36px;
  font-weight: 400;
  line-height: 38.6px;
  letter-spacing: -0.04em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #7623FF;
  margin: 0;
  padding: 0;
  padding-left: 30px;
  margin-right: 15px;
}

.textShowPhotos {
  font-family: Avenir;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000000;
  margin: 0;
  padding: 0;
  padding-right: 27px;
}

.mobile {
  display: none;

  @media (max-width: 1020px) {
    display: flex;
    height: 250px;
    width: 100%;
    position: relative;
  }
}

.photo {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.numberPhoto {
  position: absolute;
  left: 19px;
  bottom: 15px;
  background: #000000;
  height: 26px;
  font-family: Avenir;
  font-weight: 400;
  font-size: 16px;
  line-height: 21.86px;
  letter-spacing: 0%;
  text-align: center;
  margin: 0;
  padding: 0;
  color: #ffffff;
  padding-left: 4px;
  padding-right: 4px;
  display: flex;
  align-items: center;
}

.iconLogo {
  width: 47px;
  height: 66px;
  object-fit: cover;
  object-position: center;
  padding-top: 8px;
}

.textNoPhoto {
  font-family: Avenir;
  font-weight: 800;
  font-size: 18px;
  line-height: 40px;
  letter-spacing: -2%;
  color: #CFCFCF;
  text-align: center;
  margin: 0;
  padding: 0;
}