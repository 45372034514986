.modal {

    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 4000;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    max-width: 100%;
    overflow-x: hidden;
    width: 100vw;
}

.content {
    background: #ffffff;
    box-shadow: 0px 25px 50px -12px #00000040;
    border-radius: 16px;
    position: relative;
    z-index: 10;
    width: 100%;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;

}

.top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 65px;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
}

.textTop {
    font-family: Avenir;
    font-size: 18px;
    font-weight: 800;
    line-height: 24.59px;
    letter-spacing: -0.02em;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #000000;
    margin: 0;
    padding: 0;
}

.buttonClose {
    width: 24px;
    height: 24px;
    cursor: pointer;

    &:hover {
        opacity: 0.7;
    }
}

.item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
}

.textOption {
    font-family: Avenir;
    font-size: 14px;
    font-weight: 800;
    line-height: 19.12px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #000000;
    margin: 0;
    padding: 0;
}

.controls {
    display: flex;
    align-items: center;

    span {
        margin: 0;
        padding: 0;
        min-width: 24px;
        margin-left: 8px;
        margin-right: 8px;
        font-family: Avenir;
        font-size: 14px;
        font-weight: 400;
        line-height: 19.12px;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #000000;
    }
}

.button {
    border: 1px solid #E2E2E2;
    background: #ffffff;
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 20px;

    &:disabled {
        opacity: 0.2;
    }

    img {
        width: 24px;
        height: 24px;
    }
}

.bottom {
    width: 100%;
    display: flex;
    height: 86px;
    padding: 20px;
    box-sizing: border-box;
    margin-top: 57px;
    align-items: center;
}

.buttonDone {
    width: 100%;
    height: 54px;
    border-radius: 16px;
    border: none;
    background: #7623FF;
    font-family: Avenir;
    font-size: 18px;
    font-weight: 400;
    line-height: 24.59px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #ffffff;
    margin: 0;
    padding: 0;
    cursor: pointer;

    &:hover {
        opacity: 0.7;
    }
}