.section {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.top {
    display: flex;
    padding-left: 40px;
    height: 52px;
    padding-top: 8px;
    padding-bottom: 8px;
    box-sizing: border-box;
    margin-top: -54px;
    box-sizing: border-box;
}

.desktop {
    @media (max-width: 1220px) {
        display: none;
    }
}

.buttonGoBack {
    height: 36px;
    background: #FFFFFF;
    border: 1px solid #E2E2E2;
    border-radius: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 0;
    padding: 0;
}

.iconButtonGoBack {
    width: 20px;
    height: 20px;
    padding-left: 16px;
    margin-right: 4px;
}

.textInButtonGoBack {
    font-family: Avenir;
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0%;
    color: #000000;
    text-align: left;
    margin: 0;
    padding: 0;
    padding-right: 16px;
}

.content {
    display: flex;
    margin-top: 68px;
    margin-bottom: 124px;
    margin-left: auto;
    margin-right: auto;
    align-items: center;

    @media (max-width: 1220px) {
        flex-direction: column-reverse;
        margin-top: 36px;
        margin-bottom: 36px;
        padding-left: 20px;
        padding-right: 20px;
        margin-left: 0;
        margin-right: 0;
    }
}

.texts {
    display: flex;
    flex-direction: column;
    margin-right: 24px;
    width: 502px;

    @media (max-width: 1220px) {
        margin-right: 0;
        width: 100%;
    }
}

.title {
    font-family: Avenir;
    font-weight: 800;
    font-size: 128px;
    line-height: 124px;
    letter-spacing: -2%;
    vertical-align: middle;
    color: #000000;
    margin: 0;
    padding: 0;
    margin-bottom: 24px;
    text-align: left;

    @media (max-width: 1220px) {
        font-size: 32px;
        line-height: 40px;
    }
}

.subtitle {
    font-family: Avenir;
    font-weight: 800;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -2%;
    color: #000000;
    text-align: left;
    margin: 0;
    padding: 0;
    margin-bottom: 16px;

    @media (max-width: 1220px) {
        font-size: 16px;
        line-height: 100%;
        letter-spacing: 0%;
    }
}

.text {
    font-family: Avenir;
    font-weight: 400;
    font-size: 18px;
    line-height: 100%;
    letter-spacing: -2%;
    color: #000000;
    margin: 0;
    padding: 0;
    margin-bottom: 16px;
    text-align: left;

    @media (max-width: 1220px) {
        font-size: 16px;
        letter-spacing: 0%;
    }
}

.link {
    margin: 0;
    padding: 0;
    margin-bottom: 16px;
    font-family: Avenir;
    font-weight: 400;
    font-size: 18px;
    line-height: 100%;
    letter-spacing: -2%;
    color: #7623FF;
    text-align: left;
    text-decoration: none;

    &:last-child {
        margin-bottom: 0;
    }

    @media (max-width: 1220px) {
        font-size: 16px;
        line-height: 100%;
        letter-spacing: 0%;
    }
}

.icon404 {
    width: 602px;
    height: 309px;
    object-fit: contain;

    @media (max-width: 1220px) {
        margin-bottom: 24px;
        width: 100%;
        height: 172px;
    }
}

.rightBlock {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.mobile {
    display: none;

    @media (max-width: 1220px) {
        display: block;
    }
}