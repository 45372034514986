.reviews {
    width: 100%;
    margin-bottom: 32px;
    border-top: 1px solid #E2E2E2;

    @media (max-width: 1020px) {
        margin-bottom: 24px;
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
    }

    .reviews-top {
        display: flex;
        justify-content: space-between;
        padding-top: 32px;
        width: 100%;

        @media (max-width: 1020px) {
            padding-top: 24px;
        }
    }

    .reviews-heading {
        margin: 0;
        padding: 0;
        margin-bottom: 8px;
        font-family: Avenir;
        font-size: 32px;
        font-weight: 800;
        line-height: 40px;
        letter-spacing: -0.02em;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #000000;

        @media (max-width: 1020px) {
            font-size: 24px;
            line-height: 32.78px;
        }
    }

    .reviews-subheading {
        margin: 0;
        padding: 0;
        margin-bottom: 32px;
        font-family: Avenir;
        font-size: 18px;
        font-weight: 400;
        line-height: 24.59px;
        letter-spacing: -0.02em;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #747E7F;

        @media (max-width: 1020px) {
            font-size: 16px;
            line-height: 21.86px;
            margin-bottom: 24px;
        }
    }

    .reviews-list {
        display: flex;
        flex-wrap: wrap;
        gap: 24px;
    }

    .review-item {
        display: flex;
        flex-direction: column;
        width: calc(33% - 16px);
        /* Три отзыва в строке */
        gap: 16px;

        @media (max-width: 1020px) {
            width: 100%;
        }

        .review-avatar {
            width: 54px;
            height: 54px;
            flex-shrink: 0;
            margin-right: 16px;

            img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                object-fit: cover;
            }
        }

        .review-content {
            flex: 1;

            .review-name {
                margin: 0;
                padding: 0;
                margin-bottom: 4px;
                font-family: Avenir;
                font-size: 18px;
                font-weight: 800;
                line-height: 24.59px;
                letter-spacing: -0.02em;
                text-align: left;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;
                color: #000000;
            }

            .review-details {
                display: flex;
                align-items: center;

                .review-rating {
                    display: flex;
                    align-items: center;

                    .review-star-icon {
                        width: 20px;
                        height: 20px;
                    }

                    .review-rating-text {
                        margin: 0;
                        padding: 0;
                        margin-right: 8px;
                        margin-left: 2px;
                        font-family: Avenir;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 19.12px;
                        text-align: left;
                        text-underline-position: from-font;
                        text-decoration-skip-ink: none;
                        color: #7623FF;
                    }
                }

                .review-date {
                    margin: 0;
                    padding: 0;
                    margin-right: 8px;
                    font-family: Avenir;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 19.12px;
                    text-align: left;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;
                    color: #000000;
                }

                .review-location {
                    margin: 0;
                    padding: 0;
                    font-family: Avenir;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 19.12px;
                    text-align: left;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;
                    color: #747E7F;
                }
            }
        }
    }

    .reviews-show-more {
        display: block;
        background: none;
        border: none;
        cursor: pointer;
        font-family: Avenir;
        font-size: 18px;
        font-weight: 400;
        line-height: 24.59px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #7623FF;

        @media (max-width: 1020px) {
            margin-top: 24px;
        }

        &:hover {
            opacity: 0.7;
        }
    }
}

.flex {
    display: flex;
}

.review-text {
    margin: 0;
    padding: 0;
    font-family: Avenir;
    font-size: 18px;
    font-weight: 400;
    line-height: 24.59px;
    letter-spacing: -0.02em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #000000;

    @media (max-width: 1020px) {
        font-size: 16px;
        line-height: 21.86px;
    }
}