.page {
    background: #FAFBFC;
}

.top {
    height: 52px;
    width: 100%;
    padding-left: 40px;
    display: flex;
    align-items: center;
    margin-top: -54px;
    box-sizing: border-box;

    @media (max-width: 1439px) {
        margin-top: 0;
        padding-left: 16px;
        padding-right: 16px;
        padding-top: 8px;
        padding-bottom: 8px;
        justify-content: space-between;
    }
}

.buttonGoBack {
    height: 36px;
    display: flex;
    align-items: center;
    background: #FFFFFF;
    border: 1px solid #E2E2E2;
    border-radius: 20px;
    margin: 0;
    padding: 0;
    cursor: pointer;

    @media (max-width: 1439px) {
        width: 36px;
        justify-content: center;
    }
}

.iconButtonGoBack {
    width: 20px;
    height: 20px;
    padding-left: 16px;
    margin-right: 4px;

    @media (max-width: 1439px) {
        margin-right: 0;
        padding-left: 0;
    }
}

.titleTop {
    font-family: Avenir;
    font-weight: 800;
    font-size: 24px;
    line-height: 100%;
    letter-spacing: -2%;
    text-align: center;
    color: #000000;
    margin: 0;
    padding: 0;
}

.emptyBlock {
    width: 36px;
    height: 36px;
}

.textInButtonGoBack {
    font-family: Avenir;
    font-weight: 400;
    font-size: 14px;
    line-height: 19.12px;
    letter-spacing: 0%;
    color: #000000;
    text-align: left;
    margin: 0;
    padding: 0;
    padding-right: 16px;
}

.content {
    display: flex;
    width: 100%;
    justify-content: space-between;

    @media (max-width: 1439px) {
        flex-direction: column-reverse;
        justify-content: unset;
    }
}

.leftContent {
    display: flex;
    flex-direction: column;
    width: 667px;

    @media (max-width: 1439px) {
        width: 100%;
    }
}

.section {
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    @media (max-width: 1439px) {
        padding-left: 20px;
        padding-right: 20px;
    }
}

.chooseHowPayBlock {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-bottom: 32px;

    @media (max-width: 1020px) {
        padding-bottom: 24px;
        margin-bottom: 24px;
        border-bottom: 1px solid #E2E2E2;
    }
}

.titleChooseHowPay {
    font-family: Avenir;
    font-weight: 800;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -2%;
    color: #000000;
    margin: 0;
    padding: 0;
    text-align: left;
    padding-top: 32px;
    margin-bottom: 32px;

    @media (max-width: 1020px) {
        margin-bottom: 24px;
        padding-top: 0;
        font-size: 24px;
        line-height: 100%;
    }
}

.radioButtons {
    display: flex;
    width: 100%;

    @media (max-width: 1020px) {
        flex-direction: column;
    }
}

.radioButtonBLock {
    display: flex;
    width: 321.5px;
    min-height: 169px;
    padding: 24px;
    box-sizing: border-box;
    margin-right: 24px;
    background: #FFFFFF;
    border: 1px solid #E2E2E2;
    justify-content: space-between;
    border-radius: 16px;

    &:last-child {
        margin-right: 0;
    }

    @media (max-width: 1020px) {
        margin-right: 0;
        width: 100%;
        padding: 16px;
        min-height: auto;

        &:first-child {
            margin-bottom: 8px;
        }
    }
}

.textsInRadioButton {
    display: flex;
    flex-direction: column;
    margin-right: 16px;
}

.textTopInRadioButton {
    font-family: Avenir;
    font-weight: 800;
    font-size: 18px;
    line-height: 24.59px;
    letter-spacing: -2%;
    color: #000000;
    margin: 0;
    padding: 0;
    margin-bottom: 4px;
    text-align: left;

    @media (max-width: 1020px) {
        line-height: 100%;
    }
}

.textBottomInRadioButton {
    font-family: Avenir;
    font-weight: 400;
    font-size: 16px;
    line-height: 21.86px;
    letter-spacing: 0%;
    color: #747E7F;
    margin: 0;
    padding: 0;

    @media (max-width: 1020px) {
        font-size: 14px;
        line-height: 100%;
    }
}

.buttonShowMore {
    font-family: Avenir;
    font-weight: 400;
    font-size: 16px;
    line-height: 21.86px;
    letter-spacing: 0%;
    color: #7623FF;
    margin: 0;
    padding: 0;
    border: none;
    background: none;
    text-align: left;
    cursor: pointer;
    margin-top: 4px;

    @media (max-width: 1020px) {
        font-size: 14px;
        line-height: 100%;
    }
}

.radioButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: 16px;
    border: 1px solid #64748B;
    cursor: pointer;
}

.iconSelected {
    width: 20px;
    height: 20px;
}

.active {
    border: none;
}

.howPayBlock {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-bottom: 32px;

    @media (max-width: 1020px) {
        padding-bottom: 24px;
        margin-bottom: 24px;
        border-bottom: 1px solid #E2E2E2;
    }
}

.topHowPay {
    height: 40px;
    display: flex;
    align-items: center;
    padding-top: 32px;
    margin-bottom: 32px;
    width: 100%;

    @media (max-width: 1020px) {
        padding-top: 0;
        margin-bottom: 24px;
    }
}

.titleHowPay {
    font-family: Avenir;
    font-weight: 800;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -2%;
    color: #000000;
    margin: 0;
    padding: 0;
    text-align: left;
    width: 50%;

    @media (max-width: 1020px) {
        width: 100%;
        font-size: 24px;
        line-height: 100%;
    }
}

.systemsCardsBlock {
    display: flex;
    height: 18px;
    align-items: center;
    width: 50%;
    justify-content: space-between;

    @media (max-width: 1020px) {
        display: none;
    }
}

.visaIcon {
    width: 30.56px;
    height: 9.83px;
    object-fit: contain;
    cursor: pointer;
}

.masterIcon {
    width: 21.83px;
    height: 13.50px;
    object-fit: contain;
    cursor: pointer;
}

.americanExpressIcon {
    width: 27.44px;
    height: 9.87px;
    object-fit: contain;
    cursor: pointer;
}

.applePayIcon {
    width: 34.36px;
    height: 12.54px;
    object-fit: contain;
    cursor: pointer;
}

.googlePayIcon {
    width: 30px;
    height: 12px;
    object-fit: contain;
    cursor: pointer;
}

.payPalIcon {
    width: 67.63px;
    height: 18px;
    object-fit: contain;
    cursor: pointer;
}

.bottomHowPayBlock {
    display: flex;
    width: 100%;
    padding: 24px;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;
    height: 100px;
    position: relative;
    cursor: pointer;

    @media (max-width: 1020px) {
        padding: 16px;
        height: 68px;
    }
}

.logoPaymant {
    min-width: 71px;
    height: 52px;
    margin-right: 16px;
    align-items: center;
    justify-content: center;
    display: flex;

    @media (max-width: 1020px) {
        height: 36px;
        min-width: 49.15px;
    }
}

.iconLogoPayment {
    width: 42.16px;
    height: 26.06px;
    object-fit: contain;

    @media (max-width: 1020px) {
        width: 29.19px;
        height: 18.05px;
    }
}

.dataCard {
    width: 100%;
    height: 25px;
    display: flex;
    align-items: center;
    border: none;
    background: none;
    margin: 0;
    padding: 0;
    margin-right: 16px;
    text-align: left;
    font-family: Avenir;
    font-weight: 400;
    font-size: 18px;
    line-height: 24.59px;
    letter-spacing: -2%;
    color: #000000;

    &:focus {
        outline: none;
        border: none;
    }

    @media (max-width: 1020px) {
        font-size: 16px;
        line-height: 100%;
        letter-spacing: 0%;
    }
}

.dropDownIcon {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.tripBlock {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 32px;
    padding-bottom: 32px;

    @media (max-width: 1020px) {
        margin-bottom: 24px;
        padding-bottom: 24px;
        border-bottom: 1px solid #E2E2E2;
    }
}

.tripTitle {
    font-family: Avenir;
    font-weight: 800;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -2%;
    color: #000000;
    margin: 0;
    padding: 0;
    text-align: left;
    padding-top: 32px;
    margin-bottom: 32px;

    @media (max-width: 1020px) {
        padding-top: 0;
        margin-bottom: 24px;
        font-size: 24px;
        line-height: 100%;
    }
}

.titleMessageHost {
    font-family: Avenir;
    font-weight: 800;
    font-size: 18px;
    line-height: 24.59px;
    letter-spacing: -2%;
    color: #000000;
    margin: 0;
    padding: 0;
    margin-bottom: 4px;
    text-align: left;

    @media (max-width: 1020px) {
        font-size: 16px;
        line-height: 100%;
        letter-spacing: 0%;
    }
}

.textMessage {
    font-family: Avenir;
    font-weight: 400;
    font-size: 18px;
    line-height: 24.59px;
    letter-spacing: -2%;
    color: #747E7F;
    margin: 0;
    padding: 0;
    margin-bottom: 32px;
    text-align: left;

    @media (max-width: 1020px) {
        margin-bottom: 24px;
        font-size: 14px;
        line-height: 100%;
        letter-spacing: 0%;
    }
}

.hostBlock {
    margin-bottom: 32px;
    display: flex;
    height: 54px;

    @media (max-width: 1020px) {
        margin-bottom: 24px;
    }
}

.avatar {
    height: 54px;
    width: 54px;
    object-fit: contain;
    margin-right: 16px;
    border-radius: 50%;
}

.hostDetails {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.nameHost {
    font-family: Avenir;
    font-weight: 800;
    font-size: 18px;
    line-height: 24.59px;
    letter-spacing: -2%;
    color: #000000;
    margin: 0;
    padding: 0;
    margin-bottom: 4px;
    text-align: left;

    @media (max-width: 1020px) {
        font-size: 16px;
        line-height: 100%;
        letter-spacing: 0%;
    }
}

.textDate {
    font-family: Avenir;
    font-weight: 400;
    font-size: 16px;
    line-height: 21.86px;
    letter-spacing: 0%;
    color: #747E7F;
    margin: 0;
    padding: 0;
    text-align: left;

    @media (max-width: 1020px) {
        font-size: 14px;
        line-height: 100%;
    }
}

.tapMessage {
    margin: 0;
    padding: 0;
    padding: 16px;
    font-family: Avenir;
    font-weight: 400;
    font-size: 16px;
    line-height: 21.86px;
    letter-spacing: 0%;
    text-align: left;
    color: #747E7F;
    background: #F1F2F2;
    height: 54px;
    border: 1px solid #0000001A;
    border-radius: 16px;
    box-sizing: border-box;
}

.cancelBLock {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;

    @media (max-width: 1439px) {
        order: -1;
        padding-bottom: 24px;
        border-bottom: 1px solid #E2E2E2;
        margin-bottom: 24px;
    }
}

.contentCancelBlock {
    display: flex;
    flex-direction: column;

    @media (max-width: 1439px) {
        padding: 16px;
        width: 100%;
        box-sizing: border-box;
        background: #F1F2F2;
    }
}

.titleCancel {
    font-family: Avenir;
    font-weight: 800;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -2%;
    color: #000000;
    text-align: left;
    margin: 0;
    padding: 0;
    padding-top: 32px;
    margin-bottom: 32px;

    @media (max-width: 1439px) {
        display: none;
    }
}

.subtitleCancel {
    font-family: Avenir;
    font-weight: 800;
    font-size: 18px;
    line-height: 24.59px;
    letter-spacing: -2%;
    text-align: left;
    color: #000000;
    margin: 0;
    padding: 0;
    margin-bottom: 4px;

    @media (max-width: 1439px) {
        margin-bottom: 8px;
        font-size: 16px;
        line-height: 100%;
        letter-spacing: 0%;
    }
}

.cancelBeforeText {
    font-family: Avenir;
    font-weight: 400;
    font-size: 16px;
    line-height: 21.86px;
    letter-spacing: 0%;
    color: #747E7F;
    margin: 0;
    padding: 0;
    text-align: left;
    margin-bottom: 4px;

    @media (max-width: 1439px) {
        margin-bottom: 8px;
        font-size: 14px;
        line-height: 100%;
        letter-spacing: 0%;
    }
}

.buttonCancel {
    font-family: Avenir;
    font-weight: 400;
    font-size: 16px;
    line-height: 21.86px;
    letter-spacing: 0%;
    color: #7623FF;
    text-align: left;
    margin: 0;
    padding: 0;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    border: none;
    background: none;
    width: fit-content;

    @media (max-width: 1439px) {
        font-size: 14px;
        line-height: 100%;
        letter-spacing: 0%;
    }
}

.groundBlock {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
    width: 100%;

    @media (max-width: 1020px) {
        margin-bottom: 24px;
    }
}

.titleGround {
    font-family: Avenir;
    font-weight: 800;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -2%;
    color: #000000;
    margin: 0;
    padding: 0;
    padding-top: 32px;
    margin-bottom: 32px;
    text-align: left;

    @media (max-width: 1020px) {
        padding-top: 0;
        margin-bottom: 24px;
        font-size: 24px;
        line-height: 100%;
    }
}

.textGround {
    font-family: Avenir;
    font-weight: 400;
    font-size: 18px;
    line-height: 24.59px;
    letter-spacing: -2%;
    color: #747E7F;
    margin: 0;
    padding: 0;
    width: 100%;
    text-align: left;
    margin-bottom: 4px;

    @media (max-width: 1020px) {
        font-size: 16px;
        line-height: 100%;
        letter-spacing: 0%;
    }
}

.ulGround {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0;
    list-style: none;
    padding: 0;
}

.textInGround {
    font-family: Avenir;
    font-weight: 400;
    font-size: 18px;
    line-height: 24.59px;
    letter-spacing: -2%;
    color: #747E7F;
    text-align: left;
    position: relative;
    padding-left: 32px;

    @media (max-width: 1020px) {
        font-size: 16px;
        line-height: 100%;
        letter-spacing: 0%;
    }

    &::before {
        content: "•";
        font-size: 18px;
        color: #747E7F;
        position: absolute;
        left: 16px;
        top: 50%;
        transform: translateY(-50%);

        @media (max-width: 1020px) {
            font-size: 16px;
        }
    }
}

.reservationBlock {
    margin: 0;
    width: 100%;
    padding: 24px;
    box-sizing: border-box;
    background: #F1F2F2;
    font-family: Avenir;
    font-weight: 400;
    font-size: 18px;
    line-height: 24.59px;
    letter-spacing: -2%;
    color: #000000;
    margin-bottom: 32px;

    @media (max-width: 1020px) {
        font-size: 16px;
        line-height: 100%;
        letter-spacing: 0%;
        margin-bottom: 24px;
    }
}

.bookAndPayBlock {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.bookAndPayText {
    font-family: Avenir;
    font-weight: 400;
    font-size: 14px;
    line-height: 19.12px;
    letter-spacing: 0%;
    color: #747E7F;
    text-align: left;
    margin: 0;
    padding: 0;

    &:last-of-type {
        margin-bottom: 14px;

        @media (max-width: 1020px) {
            margin-bottom: 16px;
        }
    }
}

.payButton {
    height: 54px;
    width: 100%;
    border-radius: 16px;
    border: none;
    background: #7623FF;
    font-family: Avenir;
    font-weight: 400;
    font-size: 18px;
    line-height: 24.59px;
    letter-spacing: 0%;
    color: #ffffff;
    text-align: center;
    margin: 0;
    padding: 0;
    cursor: pointer;
}
