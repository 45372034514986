.shareBlock {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-top: 1px solid #E2E2E2;
    padding-top: 24px;
    height: 29.23px;
    margin-bottom: 24px;
    width: 100%;
}

.label {
    margin: 0;
    padding: 0;
    margin-right: auto;
    font-family: Avenir;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    letter-spacing: 0%;
    color: #000000;
    text-align: left;
}

.icons {
    display: flex;
    align-items: center;
    gap: 11px;

    a {
        display: inline-block;
        width: 29.23px;
        height: 29.23px;
        overflow: hidden;
        transition: transform 0.2s ease;

        &:hover {
            transform: scale(1.1);
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}