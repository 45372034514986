.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 24px;
    padding-bottom: 24px;
    z-index: 2000;
    height: 102px;
    box-sizing: border-box;
    max-width: 1440px;
    margin-bottom: 54px;

    @media (max-width: 1020px) {
        margin-bottom: 0;
        height: 60.38px;
        padding: 0;
    }

    .header-content {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
    }
}

.locationMarker {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    padding-left: 16px;
}

.location-tag {
    display: flex;
    margin-right: 4px;
    height: 54px;
    align-items: center;
    position: relative;
    width: 190px;
}

.location-input {
    border: none;
    background: none;
    outline: none;
    width: 100%;
    font-family: Avenir;
    font-size: 16px;
    font-weight: 400;
    line-height: 21.86px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #000000;

    &::placeholder {
        color: #747E7F;
    }

    &:focus {
        outline: none;
        border: none;
    }
}

.separator {
    width: 1px;
    height: 30px;
    background-color: #747E7F;
}

.date-block {
    height: 54px;
    width: 191px;
    margin-left: 4px;
    margin-right: 4px;
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    -webkit-tap-highlight-color: transparent;
}

.date-picker {
    position: absolute;
    top: 100%;
    left: -300px;
    z-index: 3000;
    background: white;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 16px;
    width: max-content;
    background-color: white;
}

.calendar-icon {
    width: 20px;
    height: 20px;
    padding-left: 16px;
    margin-right: 8px;

    &:hover {
        opacity: 0.7;
    }
}

.calendar-text {
    font-family: Avenir;
    font-size: 16px;
    font-weight: 400;
    line-height: 21.86px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #000000;

    &:hover {
        opacity: 0.7;
    }
}

.guests-block {
    width: 80px;
    height: 54px;
    display: flex;
    align-items: center;
    margin-left: 4px;
    margin-right: 4px;
    cursor: pointer;
    position: relative;
    -webkit-tap-highlight-color: transparent;
}

.guests-icon {
    height: 20px;
    width: 20px;
    margin-right: 8px;
    padding-left: 16px;
}

.guests-qantity {
    font-family: Avenir;
    font-size: 16px;
    font-weight: 400;
    line-height: 21.86px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #000000;
}

.adjustment-block {
    width: 54px;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 4px;
    margin-right: 4px;
}

.adjustment-icon {
    height: 20px;
    width: 20px;
    cursor: pointer;

    &:hover {
        opacity: 0.7;
    }
}

.search-icon-button {
    width: 40px;
    height: 40px;
    margin: 0;
    padding: 0;
    background: #7623FF;
    border: none;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:disabled {
        background: linear-gradient(0deg, rgba(118, 35, 255, 0.2), rgba(118, 35, 255, 0.2)),
            linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
        cursor: not-allowed;
    }

}

.search-icon {
    width: 18.67px;
    height: 18.67px;
}

.searchBlockMiddle {
    width: 52px;
    height: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header-right {
    display: flex;
    align-items: center;
    margin-right: 40px;

    @media (max-width: 1020px) {
        display: none;
    }

    .user-avatar {
        margin-left: 32px;
        position: relative;
        -webkit-tap-highlight-color: transparent;


        .avatar-image {
            width: 48px;
            height: 48px;
            border-radius: 50%;
            cursor: pointer;

            &:hover {
                opacity: 0.7;
            }
        }
    }
}

.logoGuestMainPage {
    width: 118px;
    height: 28px;
    margin-left: 40px;

    @media (max-width: 1020px) {
        width: 89.45px;
        height: 21.22px;
        margin-left: 0;
        padding-left: 20px;
    }
}

.logoGuest {
    width: 118px;
    height: 28px;
    margin-left: 40px;

    @media (max-width: 1020px) {
        width: 24px;
        height: 36px;
        margin-left: 0;
        padding-left: 20px;
    }
}

.buttonLoginMainPage {
    margin-right: 40px;
    min-width: 89px;
    height: 48px;
    border-radius: 8px;
    background: #FFFFFF;
    border: none;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    line-height: 17.07px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #112211;
    margin: 0;
    padding: 0;
    margin-left: 32px;
    cursor: pointer;

    &:hover {
        opacity: 0.7;
    }
}

.buttonLogin {
    margin-right: 40px;
    min-width: 89px;
    height: 48px;
    border-radius: 8px;
    background: #7623FF;
    border: none;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    line-height: 17.07px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #ffffff;
    margin: 0;
    padding: 0;
    margin-left: 32px;
    cursor: pointer;

    &:hover {
        opacity: 0.7;
    }
}

.dropDownMenu {
    display: none;
    -webkit-tap-highlight-color: transparent;

    @media (max-width: 1020px) {
        display: flex;
        width: 24px;
        height: 24px;
        padding-right: 20px;
        cursor: pointer;

        &:hover {
            opacity: 0.7;
        }

        &:focus {
            outline: none;
            box-shadow: none;
        }
    }
}

.logo {
    width: 112px;
    height: 33px;
    position: absolute;
    top: 10.5px;
    left: 8px;
    cursor: pointer;

    &:hover {
        opacity: 0.7;
    }
}

.picture {
    cursor: pointer;

    &:hover {
        opacity: 0.7;
    }
}

.mobile {
    display: none;

    @media (max-width: 1020px) {
        display: flex;
        margin-left: 20.5px;
        margin-right: 20.5px;
        width: 100%;
        height: 42px;
        border: 1.5px solid #0000001A;
        border-radius: 16px;
        background: #FFFFFF;
    }
}

.desktop {
    display: flex;

    @media (max-width: 1020px) {
        display: none;
    }
}

.searchBlockMobile {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    cursor: pointer;
}

.leftSearchBlock {
    display: flex;
    width: 100%;
    align-items: center;
    -webkit-tap-highlight-color: transparent;
}

.textSearchBlock {
    margin: 0;
    padding: 0;
    font-family: Avenir;
    font-size: 16px;
    font-weight: 400;
    line-height: 21.86px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #000000;
}

.searchIconMobileButton {
    width: 34px;
    height: 34px;
    margin: 0;
    padding: 0;
    margin-right: 4px;
    background: #7623FF;
    border: none;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:disabled {
        background: linear-gradient(0deg, rgba(118, 35, 255, 0.2), rgba(118, 35, 255, 0.2)),
            linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
        cursor: not-allowed;
    }
}

.searchIconMobile {
    width: 15.87px;
    height: 15.87px;
    object-fit: cover;
}

.navigation {
    display: flex;
    align-items: center;
    height: 54px;
    border: 1.5px solid #0000001A;
    box-shadow: 0px 12px 24px 0px #5D606317;
    background: #FFFFFF;
    border-radius: 16px;
}

.activeElement {
    background: #F1F2F2;
    outline: 1.5px solid #E2E2E2;
    border-radius: 16px;
    box-sizing: border-box;
}

.sticky {
    background: #FAFBFC;
    position: sticky;
    top: 0;
    z-index: 3000;
}

.clearButtonIcon {
    width: 20px;
    height: 20px;
    margin-left: 8px;
    padding-right: 17px;
    cursor: pointer;
}