.galleryWrapper {
    position: relative;
    width: 100%;
    margin-bottom: 36px;
}

.swiper {
    width: 100%;
    height: auto;
}

.image {
    width: 100%;
    height: 540px;
    object-fit: cover;
}

.imageSmall {
    width: 100%;
    height: 503px;
    object-fit: cover;
}

.bottomControls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 24px;
    min-height: 36px;
}

.caption {
    font-family: Avenir;
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0%;
    color: #000000;
    text-align: left;
    margin: 0;
    padding: 0;
    margin-right: 4px;
}

.rightBottomControls {
    display: flex;
    align-items: center;
    height: 36px;
}

.arrows {
    display: flex;
    gap: 8px;
}

.arrowButton {
    background: #FFFFFF;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    border: 1px solid #E2E2E2;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    img {
        width: 20px;
        height: 20px;
    }
}

.bullets {
    display: flex;
    gap: 8px;
    height: 13.53px;
    margin-right: 16px;
    align-items: center;
}

.bullet {
    width: 5.78px;
    height: 5.53px;
    border-radius: 50%;
    background: #E2E2E2;
    transition: background-color 0.3s;
}

.bullet.active {
    background-color: #7623FF;
}