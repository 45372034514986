.booking-page {
    min-height: 100vh;
    background: #FAFBFC;
    display: flex;
    flex-direction: column;

    @media (max-width: 1020px) {
        overflow-x: hidden;
    }
}

.content {
    display: flex;
}

.left {
    margin-right: 24px;
}

.hidden {
    opacity: 0;
}

.mainBlock {
    display: flex;
    flex-direction: column;
    padding-left: 40px;
    padding-right: 40px;
    width: 100%;
    box-sizing: border-box;

    @media (max-width: 1020px) {
        padding: 0;
    }
}

.stickyBlock {
    position: sticky;
    top: 0;
    z-index: 3000;

    @media (max-width: 1020px) {
        position: fixed;
        width: 100vw; 
        left: 0;
        top: 0;
    }
}

.emptyBlock {
    height: 86px;
    width: 100%;
}