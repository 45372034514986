.map-container {
    width: 100%;
    margin-bottom: 32px;
    border-top: 1px solid #E2E2E2;

    @media (max-width: 1020px) {
        margin-bottom: 24px;
        padding-left: 20px;
        padding-right: 20px;
        width: 100vw;
        box-sizing: border-box;
    }

    .map-heading {
        margin: 0;
        padding: 0;
        padding-top: 32px;
        margin-bottom: 8px;
        font-family: Avenir;
        font-size: 32px;
        font-weight: 800;
        line-height: 40px;
        letter-spacing: -0.02em;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #000000;

        @media (max-width: 1020px) {
            padding-top: 24px;
            font-size: 24px;
            line-height: 32.78px;
            max-width: 100%;
        }
    }

    .map-address {
        margin: 0;
        padding: 0;
        margin-bottom: 32px;
        font-family: Avenir;
        font-size: 18px;
        font-weight: 400;
        line-height: 24.59px;
        letter-spacing: -0.02em;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #747E7F;

        @media (max-width: 1020px) {
            font-size: 16px;
            line-height: 21.86px;
            margin-bottom: 24px;
            max-width: 100%;
        }
    }

    .leaflet-map {
        width: 100%;
        height: 536px;
        border-radius: 16px;
    }
}

:global {
    .leaflet-control-attribution {
        display: none !important;
    }
}

.mobile {
    display: none;

    @media (max-width: 1020px) {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
}

.subtitle {
    margin: 0;
    padding: 0;
    font-family: Avenir;
    font-weight: 800;
    letter-spacing: -2%;
    color: #000000;
    margin-bottom: 12px;
    font-size: 24px;
    line-height: 32.78px;

    @media (max-width: 1020px) {
        font-size: 18px;
        line-height: 24.59px;
    }

}

.infrastructureList {
    list-style: none;
    padding: 0;
    margin: 0;
    padding-bottom: 23px;

    @media (max-width: 1020px) {
        margin-bottom: 24px;
        padding-bottom: 0;
    }
}

.infrastructureItem {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
    height: 25px;
    margin-bottom: 12px;
    justify-content: space-between;

    &:last-child {
        margin-bottom: 0;
    }
}

.icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
}

.typeInfrastructure {
    margin: 0;
    padding: 0;
    font-family: Avenir;
    font-weight: 400;
    font-size: 18px;
    line-height: 24.59px;
    letter-spacing: -2%;
    color: #000000;
    text-align: left;
    width: 100%;
    max-width: 100%;
}

.distance {
    font-family: Avenir;
    font-weight: 400;
    font-size: 18px;
    line-height: 24.59px;
    letter-spacing: -2%;
    color: #000000;
    text-align: right;
    margin: 0;
    padding: 0;
}

.item {
    display: flex;
    align-items: center;
    width: 100%;
    margin-right: 8px;
}

.showMore {
    display: block;
    background: none;
    border: none;
    cursor: pointer;
    font-family: Avenir;
    font-size: 18px;
    font-weight: 400;
    line-height: 24.59px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #7623FF;
    margin: 0;
    padding: 0;
    margin-top: -12px;
    margin-bottom: 24px;
}

.infrastructure-box {
    position: absolute;
    top: 10px;
    right: 12px;
    width: 377px;
    max-height: calc(100% - 20px);
    overflow-y: auto;
    background: white;
    border-radius: 10px;
    box-shadow: 0px 22px 44px 0px #00000040;
    box-shadow: 0px 4px 4px 0px #00000040;
    z-index: 1000;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 16px;
    box-sizing: border-box;

    @media (max-width: 1020px) {
        display: none;
    }
}

.map-wrapper {
    position: relative;
    display: flex;
    width: 100%;
}