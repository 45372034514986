.modalBlock {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    box-sizing: border-box;
    max-width: 100%;
    overflow-x: hidden;
    width: 100vw;

    &::before {
        content: "";
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: url("../../assets/images/icons-for-mainPage/backgroundImageBlurDesktop.png");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        z-index: -1;


        @media (max-width: 768px) {
            background-image: url("../../assets/images/icons-for-mainPage/backgroundImageBlurMobile.png");
        }
    }
}

.section {
    width: 100%;
    box-sizing: border-box;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
    overflow-y: auto;
}

.modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 24px;
    max-width: 439px;
    width: 100%;
    text-align: center;
    align-self: center;
    position: relative;
    background: #FFFFFF;
    box-shadow: 0px 25px 50px -12px #00000040;


    @media (max-width: 768px) {
        max-width: 328px;
    }
}

.logo {
    width: 200px;
    height: 47.45px;
    margin: 0 auto;
    background-image: url("../../assets/images/logo.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @media (max-width: 768px) {
        background-image: url("../../assets/images/logoMobile.png");
        width: 140px;
        height: 33px;
    }
}

.header {
    display: flex;
    height: 95.45px;
    align-items: center;
    padding-top: 38px;
    margin-bottom: 16.55px;

    @media (max-width: 768px) {
        padding-top: 57px;
        height: 33px;
        margin-bottom: 38px;
    }
}

.title {
    font-family: Avenir;
    font-size: 24px;
    font-weight: 800;
    line-height: 32.78px;
    letter-spacing: -0.02em;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #000000;
    margin: 0;
    padding: 0;
    padding-top: 48px;
    margin-bottom: 16px;

    @media (max-width: 768px) {
        padding-top: 24px;
    }
}

.inputBlock {
    position: relative;
    display: flex;
    align-items: center;
    width: 343px;
    height: 54px;
    border-radius: 16px;
    background: #F1F2F2;
    border: 1px solid #0000001A;
    margin-bottom: 16px;
    padding-left: 16px;
    box-sizing: border-box;

    &:focus {
        outline: none;
    }

     /* Стили для автозаполнения */
     &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 1000px #F1F2F2 inset; /* Фон родителя */
        -webkit-text-fill-color: #747E7F;
      }
  
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0 1000px #F1F2F2 inset;
        -webkit-text-fill-color: #747E7F; 
      }

    @media (max-width: 768px) {
        width: 280px;
    }
}

.nextButton {
    width: 343px;
    height: 54px;
    background: linear-gradient(0deg, rgba(118, 35, 255, 0.2), rgba(118, 35, 255, 0.2)),
        linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
    border: none;
    cursor: pointer;
    border-radius: 16px;
    font-family: Avenir;
    font-size: 18px;
    font-weight: 400;
    line-height: 24.59px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #ffffff;
    margin-bottom: 16px;


    &:hover {
        opacity: 0.7;
    }

    @media (max-width: 768px) {
        width: 280px;
    }
}

.or {
    font-family: Avenir;
    font-size: 16px;
    font-weight: 400;
    line-height: 21.86px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #747E7F;
    margin: 0;
    padding: 0;
    margin-bottom: 16px;
}

.socialButtons {
    display: flex;
    margin-bottom: 16px;
    width: 343px;
    gap: 20px;
    padding-left: 70.5px;
    box-sizing: border-box;

    @media (max-width: 768px) {
        width: 280px;
        padding-left: 39px;
        padding-bottom: 24px;
    }
}

.socialButton {
    background: #F1F2F2;
    border: 1.69px solid #0000001A;
    border-radius: 16px;
    cursor: pointer;
    width: 54px;
    height: 54px;

    &:hover {
        opacity: 0.7;
    }

}

.facebookIcon {
    width: 10px;
    height: 18.33px;
}

.icon {
    width: 20px;
    height: 20px;
}

.terms {
    max-width: 343px;
    padding: 0;
    margin: 0;
    font-family: Avenir;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.12px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #747E7F;
    margin-bottom: 16px;

    .link {
        color: #7623ff;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    @media (max-width: 768px) {
        max-width: 280px;
    }
}

.closeButton {
    position: absolute;
    z-index: 2;
    top: 0;
    right: -78px;
    width: 54px;
    height: 54px;
    border-radius: 24px;
    cursor: pointer;
    border: 1.5px solid #FFFFFF;
    background: #FFFFFF4A;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        width: 54px;
        height: 54px;
    }

    &:hover {
        opacity: 0.7;
    }

    @media (max-width: 768px) {
        position: static;
        margin-top: 24px;
        align-self: center;
    }
}

.block {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modalWithCloseButton {
    display: flex;
    position: relative;
    width: 439px;
    justify-content: center;

    @media (max-width: 768px) {
        flex-direction: column;
        width: 100%;
    }
}

.googleWrapper {
    position: relative;
    display: inline-block;
    width: 54px;
    height: 54px;
    cursor: pointer;

    &:hover {
        opacity: 0.7;
    }

    /* Стилизуем глобальный класс */
    :global(.g_id_signin) {
        width: 54px;
        height: 54px;
        border: 1.69px solid #0000001a;
        box-sizing: border-box;
        border-radius: 16px;
        background: #f1f2f2;
        display: flex;
        align-items: center;
        justify-content: center;

        /* Скрываем стандартное содержимое Google, но оставляем iframe активным */
        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: none;
            z-index: 3;
            opacity: 0;
            pointer-events: auto;
            cursor: pointer;
        }
    }

    .customIcon {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background: #f1f2f2;
        border: 1.69px solid #0000001a;
        border-radius: 16px;
        box-sizing: border-box;
        background-image: url("../../assets/images/google.png");
        background-size: 19.36px 20px;
        background-repeat: no-repeat;
        background-position: center;
    }
}

.form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.inputEmail {
    padding-left: 8px;
    border: none;
    background: none;
    width: 100%;
    height: 100%;
    font-family: Avenir;
    font-size: 16px;
    font-weight: 400;
    line-height: 21.86px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #747E7F;
    box-sizing: border-box;

    &:focus {
        border: none;
        outline: none;
    }
}

.inputPhoneNumber {
    padding-left: 8px;
    border: none;
    background: none;
    width: 100%;
    height: 100%;
    font-family: Avenir;
    font-size: 16px;
    font-weight: 400;
    line-height: 21.86px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #747E7F;
    box-sizing: border-box;

    &:focus {
        border: none;
        outline: none;
    }
}

.blockCountry {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 8px;
}

.countryText {
    font-family: Avenir;
    font-size: 12px;
    font-weight: 400;
    line-height: 16.39px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #747E7F;
    padding: 0;
    margin: 0;
}

.countryCode {
    font-family: Avenir;
    font-size: 16px;
    font-weight: 400;
    line-height: 21.86px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #000000;
    margin: 0;
    padding: 0;
}

.arrowDownIcon {
    width: 20px;
    height: 20px;
    padding-right: 17px;
    padding-left: 8px;
}

.textToLogin {
    font-family: Avenir;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.12px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin: 0;
    padding: 0;
    padding-bottom: 48px;
    color: #747E7F;
    width: 343px;
}

.spanLogin {
    color: #7623FF;
    margin: 0;
    padding: 0;
    cursor: pointer;
}

.textDontSmsCode {
    font-family: Avenir;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.12px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #7623FF;
    margin: 0;
    padding: 0;
    padding-bottom: 48px;
    cursor: pointer;

    &:hover {
        opacity: 0.7;
    }
}

.buttonRegistration {
    margin-bottom: 48px;
}

.footerBlock {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
}