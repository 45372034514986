.listing-description {
    width: 100%;
    max-width: 897px;
    margin-bottom: 32px;
    border-top: 1px solid #E2E2E2;
    padding-top: 32px;

    @media (max-width: 1020px) {
        padding-top: 24px;
        width: 100vw;
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
        max-width: 100%;
        margin-bottom: 24px;
    }

    .listing-features {
        display: flex;
        justify-content: space-between;
        margin-bottom: 32px;

        @media (max-width: 1020px) {
            flex-direction: column;
            justify-content: unset;
            margin-bottom: 0;
        }

        .feature-item {
            display: flex;
            align-items: center;
            max-width: 283px;

            @media (max-width: 1020px) {
                max-width: 100%;
                margin-bottom: 24px;
            }

            .feature-icon {
                width: 55px;
                height: 55px;
                margin-right: 16px;
            }

            .feature-title {
                margin: 0;
                padding: 0;
                margin-bottom: 4px;
                font-family: Avenir;
                font-size: 18px;
                font-weight: 800;
                line-height: 24.59px;
                letter-spacing: -0.02em;
                text-align: left;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;
                color: #000000;
            }

            .feature-description {
                margin: 0;
                padding: 0;
                font-family: Avenir;
                font-size: 16px;
                font-weight: 400;
                line-height: 21.86px;
                text-align: left;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;
                color: #747E7F;
            }
        }
    }

    .translation-info {
        background-color: #F1F2F2;
        border-radius: 8px;
        margin-bottom: 32px;
        min-height: 57px;

        @media (max-width: 1020px) {
            margin-bottom: 24px;
            min-height: 76px;
            margin-bottom: 24px;
        }

        p {
            margin: 0;
            padding: 0;
            padding-top: 16px;
            font-family: Avenir;
            font-size: 18px;
            font-weight: 400;
            line-height: 24.59px;
            letter-spacing: -0.02em;
            text-align: center;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: #000000;

            @media (max-width: 1020px) {
                font-size: 16px;
                line-height: 21.86px;
            }

            .translation-toggle {
                background: none;
                border: none;
                color: #7623ff;
                cursor: pointer;
                font-family: Avenir;
                font-size: 18px;
                font-weight: 400;
                line-height: 24.59px;
                text-align: left;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;
                padding: 0;

                @media (max-width: 1020px) {
                    font-size: 16px;
                    line-height: 21.86px;
                }


                &:hover {
                    opacity: 0.7;
                }
            }
        }
    }

    .description-text {
        margin: 0;
        padding: 0;
        font-family: Avenir;
        font-size: 18px;
        font-weight: 400;
        line-height: 24.59px;
        letter-spacing: -0.02em;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #000000;

        @media (max-width: 1020px) {
            font-size: 16px;
            line-height: 21.86px;
        } 

        .description-toggle {
            background: none;
            border: none;
            color: #7623ff;
            cursor: pointer;
            font-family: Avenir;
            font-size: 18px;
            font-weight: 400;
            line-height: 24.59px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            padding: 0;

            &:hover {
                opacity: 0.7;
            }
        }
    }
}