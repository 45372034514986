.searchBlock {
    display: flex;
    flex-direction: column;
    max-width: 856px;
    min-height: 127px;
    align-items: center;
    box-sizing: border-box;

    @media (max-width: 1180px) {
        padding-bottom: 0;
    }
}

.options {
    display: flex;
    width: 100%;
}

.option {
    border-radius: 16px;
    background: #FFFFFF4A;
    border: 1.5px solid #FFFFFF;
    display: flex;
    align-items: center;
    height: 54px;
    margin-right: 8px;
    position: relative;
    -webkit-tap-highlight-color: transparent;

    &:focus {
        outline: none;
        box-shadow: none;
    }
}

.findButton {
    margin: 0;
    padding: 0;
    width: 262px;
    height: 54px;
    background: #7623FF;
    color: #FFFFFF;
    border: none;
    border-radius: 16px;
    cursor: pointer;
    font-family: Avenir;
    font-size: 18px;
    font-weight: 400;
    line-height: 24.59px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;


    &:hover {
        opacity: 0.7;
    }

    &:disabled {
        background: linear-gradient(0deg, rgba(118, 35, 255, 0.1), rgba(118, 35, 255, 0.1)),
            rgba(255, 255, 255, 0.1);
        color: rgba(255, 255, 255, 0.4);
    }
}

.inputs {
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 16px;
}

.inputDestinationBlock {
    display: flex;
    align-items: center;
    flex: 1;
    height: 54px;
    border-radius: 16px;
    border: 1.5px solid #FFFFFF;
    margin-right: 8px;
    box-sizing: border-box;
    position: relative;
    -webkit-tap-highlight-color: transparent;

    &:focus {
        outline: none;
        box-shadow: none;
    }
}

.inputDestinationImage {
    width: 20px;
    height: 20px;
    padding-left: 16px;
    object-fit: cover;
}

.imageDestinationBlock {
    display: flex;
    align-items: center;
    background: #FFFFFF4A;
    width: 36px;
    height: 100%;
    padding-right: 8px;
}

.inputDestination {
    background: #FFFFFF4A;
    width: 100%;
    height: 100%;
    border: none;
    font-family: Avenir;
    font-size: 18px;
    font-weight: 400;
    line-height: 24.59px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #ffffff;
    -webkit-tap-highlight-color: transparent;

    &:focus {
        border: none;
        outline: none;
        box-shadow: none;
    }
}

.inputCalendarBlock {
    position: relative;
    display: flex;
    align-items: center;
    height: 54px;
    border: 1.5px solid #FFFFFF;
    border-radius: 16px;
    background: #FFFFFF4A;
    cursor: pointer;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;

    &:focus {
        outline: none;
        box-shadow: none;
    }
}

.inputCalendarIcon {
    width: 20px;
    height: 20px;
    padding-left: 16px;

    &:hover {
        opacity: 0.7;
    }
}

.inputCalendar {
    height: 100%;
    font-family: Avenir;
    font-size: 18px;
    font-weight: 400;
    line-height: 24.59px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #ffffff;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    min-width: 100px;
}

.halfOpacity {
    opacity: 0.5;
}

.imageCalendarBlock {
    display: flex;
    align-items: center;
    width: 36px;
    height: 100%;
}

.radiusIcon {
    width: 24px;
    height: 24px;
    padding-left: 16px;
    margin-right: 8px;
}

.usersIcon {
    width: 20px;
    height: 20px;
    padding-left: 16px;
    margin-right: 8px;
}

.optionText {
    margin: 0;
    padding: 0;
    margin-right: 8px;
    font-family: Avenir;
    font-size: 18px;
    font-weight: 400;
    line-height: 24.59px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #FFFFFF;
    opacity: 0.5;
}

.optionResult {
    font-family: Avenir;
    font-size: 18px;
    font-weight: 400;
    line-height: 24.59px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #ffffff;
    margin: 0;
    padding: 0;
    padding-right: 14.5px;
}

.separator {
    font-family: Avenir;
    font-size: 18px;
    font-weight: 400;
    line-height: 24.59px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #ffffff;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    width: 1px;
    opacity: 0.5;
    margin-left: 16px;
    margin-right: 16px;
}

.optionMobileGuest {
    display: none;
}

.adjustmentButton {
    display: none;
}

.optionMobileRadius {
    display: none;
}

@media (max-width: 910px) {

    .inputs {
        flex-direction: column;
        margin-bottom: 8px;
    }

    .inputDestinationBlock {
        width: 100%;
        height: 54px;
        margin-right: 0;
    }

    .inputDestination {
        height: 54px;
    }

    .imageDestinationBlock {
        height: 54px;
    }

    .inputCalendarBlock {
        height: 54px;
        width: 100%;
    }

    .option {
        display: none;
    }

    .findButton {
        margin-top: 8px;
        width: 100%;
    }

    .row {
        display: flex;
        width: 100%;
        height: 54px;
        margin-top: 8px;
    }

    .optionMobileRadius {
        border-radius: 16px;
        background: #FFFFFF4A;
        border: 1.5px solid #FFFFFF;
        display: flex;
        align-items: center;
        height: 54px;
        width: calc((100% - 60px)/2);
        cursor: pointer;
        position: relative;
        -webkit-tap-highlight-color: transparent;

        &:focus {
            outline: none;
            box-shadow: none;
        }
    }

    .optionMobileGuest {
        position: relative;
        border-radius: 16px;
        background: #FFFFFF4A;
        border: 1.5px solid #FFFFFF;
        display: flex;
        align-items: center;
        height: 54px;
        width: calc((100% - 60px)/2);
        margin-right: 8px;
        margin-left: 8px;
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;

        &:focus {
            outline: none;
            box-shadow: none;
        }
    }

    .adjustmentButton {
        border: 1.5px solid #FFFFFF;
        background: #FFFFFF4A;
        width: 54px;
        height: 54px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border-radius: 16px;
        margin: 0;
        padding: 0;
        -webkit-tap-highlight-color: transparent;

        &:focus {
            outline: none;
            box-shadow: none;
        }
    }

    .adjustmentIcon {
        width: 20px;
        height: 20px;
    }

    .options {
        width: 100%;
    }
}

.flex1 {
    flex: 1;
}

.date-picker {
    position: absolute;
    bottom: 56px;
    left: 0;
    z-index: 3000;
    padding: 24px;
    background: white;
    box-shadow: 0px 25px 50px -12px #00000040;
    border-radius: 16px;
    width: max-content;
    background-color: white;
    box-sizing: border-box;
}

.reset-dates-button {
    display: inline-block;
    margin-top: 16px;
    padding: 8px 16px;
    font-size: 14px;
    font-weight: 500;
    color: #7623ff;
    background: #ffffff;
    border: 1px solid #7623ff;
    border-radius: 8px;
    cursor: pointer;
    text-align: center;
    transition: background-color 0.3s, color 0.3s, opacity 0.3s;

    &:hover {
        background-color: #7623ff;
        color: #ffffff;
    }

    &:focus {
        outline: none;
        box-shadow: 0 0 4px rgba(118, 35, 255, 0.5);
    }

    &:disabled {
        background-color: #f0f0f0;
        color: #ccc;
        border-color: #ccc;
        cursor: not-allowed;
    }
}

.textInCalendarBlock {
    display: flex;
    min-width: 262px;
    padding-left: 8px;
    padding-right: 16px;
    align-items: center;
}

.mobileRow {
    display: flex;
    width: 100%;
    align-items: center;
}

.textsInButton {
    display: flex;
    flex-direction: column;
    padding-left: 16px;
    width: 100%;
}

.chevronIcon {
    padding-right: 17px;
    width: 20px;
    height: 20px;
}

.smallTextInMobileButton {
    margin: 0;
    padding: 0;
    font-family: Avenir;
    font-size: 12px;
    font-weight: 400;
    line-height: 16.39px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #ffffff;
}

.textInMobileButton {
    margin: 0;
    padding: 0;
    font-family: Avenir;
    font-size: 16px;
    font-weight: 400;
    line-height: 21.86px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #ffffff;
}

.clearButtonIcon {
    width: 18px;
    height: 18px;
    cursor: pointer;
}

.clearButtonBlock {
    height: 100%;
    width: 25.5px;
    padding-right: 14.25px;
    background: #FFFFFF4A;
    display: flex;
    align-items: center;
    padding-left: 8px;
}

.clearButton {
    width: 25.5px;
    height: 25.5px;
    background: #FFFFFF4D;
    border: 1.13px solid #FFFFFF;
    border-radius: 18px;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}