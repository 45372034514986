.bookingDetails {
    display: flex;
    flex-direction: column;
    width: 897px;
    margin-bottom: 32px;

    @media (max-width: 1020px) {
        width: 100vw;
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
        margin-bottom: 8px;
    }
}

.title-booking-details {
    padding: 0;
    margin: 0;
    margin-bottom: 8px;
    font-family: Avenir;
    font-size: 32px;
    font-weight: 800;
    line-height: 40px;
    letter-spacing: -0.02em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #000000;

    @media (max-width: 1020px) {
        font-size: 16px;
        line-height: 21.86px;
    }
}

.parameters-block {
    font-family: Avenir;
    font-size: 16px;
    font-weight: 400;
    line-height: 21.86px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin: 0;
    padding: 0;
    margin-bottom: 8px;
    color: #747E7F;
}

.chat-block {
    display: flex;
    justify-content: space-between;

    @media (max-width: 1020px)  {
        display: none;
    }
}

.chat-block-element {
    display: flex;
}

.chat-icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
}

.chat-text {
    padding: 0;
    margin: 0;
    font-family: Avenir;
    font-size: 18px;
    font-weight: 800;
    line-height: 24.59px;
    letter-spacing: -0.02em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #000000;
}

.chat-block-text {
    margin: 0;
    padding: 0;
    font-family: Avenir;
    font-size: 16px;
    font-weight: 400;
    line-height: 21.86px;
    text-align: right;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #747E7F;
    cursor: pointer;

    &:hover {
        opacity: 0.7;
    }
}