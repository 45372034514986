.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 4000;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    overflow-x: hidden;
    width: 100vw;

    @media (max-width: 1020px) {
        display: flex;
        justify-content: center;
    }
}

.modal {
    background: #fff;
    box-shadow: 0px 25px 50px -12px #00000040;
    border-radius: 24px;
    padding: 24px;
    width: 476px;
    position: relative;
    max-height: 70%;
    box-sizing: border-box;

    @media (max-width: 1020px) {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 20px;
        padding-bottom: 16px;
        width: 100%;
        max-width: 100%;
        overflow-x: hidden;
        width: 100vw;
        background: #FAFBFC;
        border-radius: 0;
        box-shadow: none;
        max-height: 100%;
    }

    .modalContent {
        max-height: calc(70vh - 48px);
        overflow-y: auto;
        overflow-x: hidden;
        /* Отступ для скролла */
        margin-right: -13px;

        // Стили для скроллбара
        ::-webkit-scrollbar {
            width: 4px;
            /* Ширина скроллбара */
        }

        ::-webkit-scrollbar-thumb {
            background-color: #B8BCBC;
            /* Цвет ползунка */
        }

        ::-webkit-scrollbar-track {
            background-color: #F1F2F2;
            /* Цвет трека (фон под ползунком) */
        }

        // Для Firefox
        scrollbar-width: thin;
        /* Уменьшенная ширина */
        scrollbar-color: #B8BCBC #F1F2F2;

        /* Цвет ползунка и трека */
        @media (max-width: 1020px) {
            max-height: 100%;
        }
    }
}

.closeButton {
    position: absolute;
    width: 54px;
    height: 54px;
    top: 0;
    right: -64px;
    cursor: pointer;
    background: #FFFFFF4A;
    border: 1.5px solid #FFFFFF;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;


    img {
        width: 24px;
        height: 24px;
    }
}

.title {
    margin: 0;
    padding: 0;
    margin-bottom: 16px;
    font-family: Avenir;
    font-size: 24px;
    font-weight: 800;
    line-height: 32.78px;
    letter-spacing: -0.02em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #000000;

    @media (max-width: 1020px) {
        font-size: 18px;
        line-height: 24.59px;
        margin-bottom: 0;
    }
}

.filterSection {
    margin-bottom: 16px;
}

.subtitle {
    margin: 0;
    padding: 0;
    margin-bottom: 8px;
    font-family: Avenir;
    font-size: 16px;
    font-weight: 800;
    line-height: 21.86px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #000000;
}

.text {
    margin: 0;
    padding: 0;
    margin-bottom: 16px;
    font-family: Avenir;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.12px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #747E7F;
}

.applyButton {
    flex-grow: 1;
    height: 54px;
    border-radius: 16px;
    background: #7623FF;
    color: #fff;
    cursor: pointer;
    border: none;
    font-family: Avenir;
    font-size: 18px;
    font-weight: 400;
    line-height: 24.59px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin: 0;
    padding: 0;

    &:disabled {
        background: linear-gradient(0deg, rgba(118, 35, 255, 0.2), rgba(118, 35, 255, 0.2)),
            linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
        cursor: not-allowed;
    }

    @media (max-width: 1020px) {
        flex-grow: unset;
        width: calc(100vw - 40px);
    }
}

.activeButton {
    background: #F1F2F2;
    width: 141.33px;
    height: 44px;
    border: none;
    cursor: pointer;
    font-family: Avenir;
    font-size: 16px;
    font-weight: 800;
    line-height: 21.86px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #000000;
    margin: 0;
    padding: 0;
    cursor: pointer;
    border-radius: 6px;


    @media (max-width: 1020px) {
        width: 33.33%;
        height: 40px;
    }
}

.button {
    background: #FFFFFF;
    width: 141.33px;
    height: 44px;
    border: none;
    cursor: pointer;
    font-family: Avenir;
    font-size: 16px;
    font-weight: 800;
    line-height: 21.86px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #747E7F;
    box-shadow: 0px 1px 2px 0px #0000000F;
    box-shadow: 0px 1px 3px 0px #0000001A;
    margin: 0;
    padding: 0;
    cursor: pointer;
    border-radius: 6px;

    @media (max-width: 1020px) {
        width: 33.33%;
        height: 40px;
    }
}

.roomButton {
    background: #FFFFFF;
    color: #000;
    border-radius: 20px;
    margin: 0;
    padding: 0;
    margin-right: 8px;
    cursor: pointer;
    border: 1px solid #E2E2E2;
    font-family: Avenir;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.12px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    height: 36px;
    width: 40px;
}

.activeRoomButton {
    background: #7623ff;
    border: none;
    color: #fff;
    width: 40px;
    height: 36px;
    border-radius: 20px;
    font-family: Avenir;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.12px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    padding: 0;
    margin: 0;
    margin-right: 8px;
    cursor: pointer;
}

.priceSliderImage {
    width: 428px;
    height: 69px;
    margin-bottom: 1px;
    padding-top: 10px;

    @media (max-width: 1020px) {
        width: 100%;

        svg {
            width: 100%;
        }

    }
}

.priceInputs {
    display: flex;

    .priceInput {
        display: flex;
        flex-direction: column;
        width: 210px;
        height: 54px;
        border: 1px solid #0000001A;
        background: #F1F2F2;
        border-radius: 16px;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 16px;
        padding-right: 16px;
        box-sizing: border-box;

        @media (max-width: 1020px) {
            width: calc(50% - 4px);
        }

        &:first-child {
            margin-right: 8px;
        }

        label {
            font-family: Avenir;
            font-size: 12px;
            font-weight: 400;
            line-height: 16.39px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            margin: 0;
            padding: 0;
            color: #747e7f;
        }

        .input {
            font-family: Avenir;
            font-size: 16px;
            font-weight: 400;
            line-height: 21.86px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: #000000;
            background: #F1F2F2;
            border: none;
            width: 100%;
            height: 100%;
            text-align: left;
            margin: 0;
            padding: 0;

            &:focus {
                outline: none;
                border: none;
            }
        }
    }
}

.priceSlider {
    width: 276px;
    height: 3px;
    box-sizing: border-box;
    margin-left: 44px;
    margin-bottom: 22px;
    border: 1px solid #7623FF;
    position: relative;

    @media (max-width: 1020px) {
        width: calc(100% - 140px);
    }
}

.sliderThumb {
    width: 12px;
    height: 12px;
    background: #7623FF;
    border-radius: 50%;
    cursor: grab;
    position: absolute;
    margin-top: -4px;
    margin-left: 2px;

    &:focus {
        outline: none;
    }
}

.firstThumb {
    margin-left: -1px;
}

.sliderTrack {
    border: 3px solid #7623FF;
}

.separator {
    border: 1px solid #E2E2E2;
    margin-bottom: 16px;
    width: 428px;

    @media (max-width: 1020px) {
        width: 100%;
    }
}

.roomFilters {
    display: flex;
    flex-direction: column;
}

.textType {
    font-family: Avenir;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.12px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #747E7F;
    margin: 0;
    padding: 0;
    margin-bottom: 8px;
}

.roomFiltersContent {
    margin-bottom: 8px;
}

.roomButtonAny {
    min-width: 57px;
}

.roomButton5Plus {
    width: 50px;
}

.buttons {
    display: flex;
    padding-top: 12px;
    width: 428px;

    @media (max-width: 1020px) {
        width: 100%;
        flex-direction: column;
        padding-top: 16px;
    }
}

.clearButton {
    text-align: center;
    font-family: Avenir;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.12px;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #747E7F;
    border: none;
    background: none;
    min-width: 58px;
    height: 54px;
    cursor: pointer;
}

.desktop {
    display: flex;

    @media (max-width: 1020px) {
        display: none;
    }
}

.mobile {
    display: none;
    ;

    @media (max-width: 1020px) {
        display: flex;
        width: 100%;
    }
}

.clearButtonMobile {
    background: none;
    height: 19px;
    border: none;
    font-family: Avenir;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.12px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #747E7F;
    margin: 0;
    padding: 0;
    margin-left: 16px;

    &:hover {
        opacity: 0.7;
    }
}

.mobileTop {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    height: 25px;
    padding-bottom: 40px;
}

.mobileTopLeft {
    display: flex;
    align-items: center;
}

.buttonClose {
    width: 24px;
    height: 24px;
    cursor: pointer;

    &:hover {
        opacity: 0.7;
    }
}

.roomButtons {

    @media (max-width: 1020px) {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
    }
}

.BlockForMobileOverflow {
    display: flex;
    flex-direction: column;
    width: 100%;

    @media (max-width: 1020px) {
        max-height: calc(100vh - 225px);
        overflow-y: auto;
        overflow-x: hidden;
        box-sizing: border-box;
        margin-right: -13px;

        // Стили для скроллбара
        ::-webkit-scrollbar {
            width: 4px;
            /* Ширина скроллбара */
        }

        ::-webkit-scrollbar-thumb {
            background-color: #B8BCBC;
            /* Цвет ползунка */
        }

        ::-webkit-scrollbar-track {
            background-color: #F1F2F2;
            /* Цвет трека (фон под ползунком) */
        }

        // Для Firefox
        scrollbar-width: thin;
        /* Уменьшенная ширина */
        scrollbar-color: #B8BCBC #F1F2F2;
        /* Цвет ползунка и трека */
    }
}