.host-info {
    display: flex;
    padding-top: 32px;
    margin-bottom: 32px;
    border-top: 1px solid #E2E2E2;

    @media (max-width: 1020px) {
        border-top: none;
        margin-bottom: 24px;
        padding-top: 0;
        padding-left: 20px;
        padding-right: 20px;
        width: 100vw;
        box-sizing: border-box;
    }

    .host-avatar {
        width: 54px;
        height: 54px;
        border-radius: 50%;
        margin-right: 16px;
        object-fit: cover;
    }

    .host-details {
        display: flex;
        flex-direction: column;

        .host-name {
            margin: 0;
            padding: 0;
            font-family: Avenir;
            font-size: 18px;
            font-weight: 800;
            line-height: 24.59px;
            letter-spacing: -0.02em;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: #000000;
        }

        .host-years {
            margin: 0;
            padding: 0;
            font-family: Avenir;
            font-size: 16px;
            font-weight: 400;
            line-height: 21.86px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: #747E7F;
        }
    }
}