.modal {
    position: absolute;
    top: 30px;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 4000;

    @media (max-width:1020px) {
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        position: fixed;
        width: 100vw;
        max-width: 100%;
        overflow-x: hidden;
        display: flex;
        align-items: end;
        background: rgba(0, 0, 0, 0.5);
    }
}

.content {
    min-width: 285px;
    background: #ffffff;
    box-shadow: 0px 25px 50px -12px #00000040;
    border-radius: 16px;
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: column;

    @media (max-width:1020px) {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        width: 100%;
        max-height: 80vh;
        min-height: 80vh;
    }
}

.option {
    display: flex;
    align-items: center;
    height: 54px;
    align-items: center;
    justify-content: space-between;
    border: none;
    background: none;
    cursor: pointer;
    margin: 0;
    padding: 0;
    padding-left: 20px;
    padding-right: 20px;

    &:hover {
        background: #F1F2F2;
    }
}

.textOption {
    font-family: Avenir;
    font-size: 16px;
    font-weight: 400;
    line-height: 21.86px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #000000;
    margin: 0;
    padding: 0;
}

.mobile {
    display: none;

    @media (max-width:1020px) {
        display: flex;
        width: 100%;
    }
}

.top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 65px;
    padding-left: 20px;
    padding-right: 20px;
}

.textTop {
    font-family: Avenir;
    font-size: 18px;
    font-weight: 800;
    line-height: 24.59px;
    letter-spacing: -0.02em;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #000000;
    margin: 0;
    padding: 0;
}

.buttonClose {
    width: 24px;
    height: 24px;
    cursor: pointer;

    &:hover {
        opacity: 0.7;
    }
}

.checkIcon {
    width: 20px;
    height: 20px;
    object-fit: cover;
}