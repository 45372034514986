.card {
    width: 310px;
    border-radius: 24px;
    background: #FFFFFF;
    box-shadow: 0px 22px 44px 0px #00000040;
    box-shadow: 0px 4px 4px 0px #00000040;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    @media (max-width: 768px) {
        width: 100%;
    }
}

.imageContainer {
    position: relative;
    width: 100%;
    height: 213px;

    @media (max-width: 768px) {
        height: 167.5px;
    }

    .image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .rating {
        position: absolute;
        top: 16px;
        left: 17.5px;
        color: #ffffff;
        font-family: Avenir;
        font-size: 18px;
        font-weight: 400;
        line-height: 24.59px;
        letter-spacing: -0.02em;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        display: flex;
        z-index: 5;
        cursor: pointer;

        &:hover {
            opacity: 0.7;
        }

        @media (max-width: 768px) {
            top: 16.5px;
            left: 16px;
        }

        .starIcon {
            height: 24px;
            width: 24px;
            margin-right: 8px;
        }
    }

    .likeButton {
        position: absolute;
        top: 14.5px;
        right: 18px;
        background: none;
        border: none;
        cursor: pointer;
        height: 24px;
        width: 24px;
        padding: 0;
        margin: 0;

        &:hover {
            opacity: 0.7;
        }
    }

    .photoSlider {
        position: absolute;
        bottom: 0;
        left: 18px;
        display: flex;
        gap: 11px;

        @media (max-width: 768px) {
            gap: 0;
            left: 8px;
            justify-content: space-between;
            width: calc(100vw - 45px);
        }

        .photoIndicator {
            height: 4px;
            border-radius: 20px 0px 0px 0px;
            width: 36.67px;
            background: #ffffff;
            opacity: 0.25;
            cursor: pointer;
            transition: opacity 0.3s ease-in-out;

            @media (max-width: 768px) {
                width: 48.83px;
            }

            &.active {
                opacity: 1;
            }
        }
    }
}

.content {
    padding: 16px;
    display: flex;
    flex-direction: column;

    @media (max-width: 768px) {
        padding-top: 12px;
        padding-bottom: 12px;
        padding-left: 14px;
        padding-right: 14px;
    }

    .header {
        display: flex;
        flex-direction: column;
        margin-bottom: 8px;

        @media (max-width: 768px) {
            margin-bottom: 16px;
        }

        h3 {
            font-family: Avenir;
            font-size: 18px;
            font-weight: 800;
            line-height: 24.59px;
            letter-spacing: -0.02em;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: #000000;
            margin: 0;
            padding: 0;
            margin-bottom: 8px;
            max-width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        p {
            font-family: Avenir;
            font-size: 14px;
            font-weight: 400;
            line-height: 19.12px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: #747E7F;
            margin: 0;
            padding: 0;
        }
    }

    .footer {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .price {
            font-family: Avenir;
            font-size: 18px;
            font-weight: 800;
            line-height: 24.59px;
            letter-spacing: -0.02em;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: #000000;
            margin: 0;
            padding: 0;
        }

        .night {
            font-family: Avenir;
            font-size: 14px;
            font-weight: 400;
            line-height: 19.12px;
            text-align: right;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: #747E7F;
            margin: 0;
            padding: 0;
        }
    }
}

.noImage {
    height: 213px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #E2E2E2;
    flex-direction: column;
}

.photoIndicatorBlock {
    padding-top: 7.5px;
    padding-bottom: 7.5px;
}

.heartIcon {
    width: 24px;
    height: 24px;
    object-fit: cover;
}

.buttonsBlock {
    display: flex;
    align-items: center;
    position: absolute;
    top: 16px;
    right: 16px;

    .buttonLike {
        width: 24px;
        height: 24px;
        border: none;
        background: none;
        margin: 0;
        padding: 0;
        margin-right: 16px;
        cursor: pointer;

        &:hover {
            opacity: 0.7;
        }
    }

    .buttonClose {
        width: 25.5px;
        height: 25.5px;
        border: none;
        background: none;
        margin: 0;
        padding: 0;
        cursor: pointer;

        .iconButtonClose {
            width: 25.5px;
            height: 25.5px;
            object-fit: cover;
        }

        &:hover {
            opacity: 0.7;
        }
    }
}

.iconLogo {
    width: 47px;
    height: 66px;
    object-fit: cover;
    object-position: center;
    padding-top: 8px;
  }
  
  .textNoPhoto {
    font-family: Avenir;
    font-weight: 800;
    font-size: 18px;
    line-height: 40px;
    letter-spacing: -2%;
    color: #CFCFCF;
    text-align: center;
    margin: 0;
    padding: 0;
  }