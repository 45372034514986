.searchResult {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    justify-content: space-between;
}

.section {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.filters {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 40px;
    padding-right: 40px;
    margin-bottom: 24px;
}

.textOffers {
    font-family: Avenir;
    font-size: 16px;
    font-weight: 500;
    line-height: 21.86px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    padding: 0;
    margin: 0;
    margin-right: 24px;
    color: #747E7F;

    span {
        font-family: Avenir;
        font-size: 16px;
        font-weight: 500;
        line-height: 21.86px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        margin: 0;
        padding: 0;
        color: #000000;
    }
}

.sortingButton {
    background: none;
    border: none;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-family: Avenir;
    font-size: 16px;
    font-weight: 400;
    line-height: 21.86px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #747E7F;
    position: relative;
    -webkit-tap-highlight-color: transparent;
    padding: 0;
}

.filterActions {
    display: flex;

    .filterButton {
        background: none;
        border: none;
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-right: 36px;
        font-family: Avenir;
        font-size: 16px;
        font-weight: 400;
        line-height: 21.86px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #747E7F;
        position: relative;
        -webkit-tap-highlight-color: transparent;

        &:last-child {
            margin-right: 0;
        }
    }
}

.icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
}

.content {
    padding-bottom: 54px;
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
    box-sizing: border-box;

    @media (max-width: 1020px) {
        padding-left: 20px;
        padding-right: 20px;
    }
}

.noResults {
    padding-left: 40px;
    padding-right: 40px;
    font-family: Avenir;
    font-size: 16px;
    font-weight: 500;
    line-height: 21.86px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}


.viewButtons {
    display: flex;
    background: #F1F2F2;
    height: 44px;
    align-items: center;
}

.buttonList {
    margin: 0;
    padding: 0;
    height: 40px;
    padding-left: 12px;
    padding-right: 19px;
    display: flex;
    align-items: center;
    background: none;
    border: none;
    cursor: pointer;

    &:hover {
        opacity: 0.7;
    }
}

.activeButton {
    background: #FFFFFF;
    box-shadow: 0px 1px 2px 0px #0000000F;
    box-shadow: 0px 1px 3px 0px #0000001A;
}

.iconButton {
    width: 20px;
    height: 20px;
    object-fit: cover;
}

.textInButton {
    font-family: Avenir;
    font-size: 16px;
    font-weight: 800;
    line-height: 21.86px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #000000;
    margin: 0;
    padding: 0;
    margin-left: 6px;
}

.activeColorText {
    color: #747E7F;
}

.mobile {
    display: none;

    @media (max-width: 1020px) {
        display: flex;
    }
}

.desktop {
    display: flex;

    @media (max-width: 1020px) {
        display: none;
    }
}

.filtersMobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    box-sizing: border-box;
    height: 56px;
}

.mobileButtonFilters {
    background: none;
    border: none;
    height: 24px;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 0;
    padding: 0;
    font-family: Avenir;
    font-size: 16px;
    font-weight: 400;
    line-height: 21.86px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #000000;
}

.floatingButton {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 1440px;
    cursor: pointer;
    z-index: 1000;
    background: #7623FF;
    border: none;
    margin: 0;
    padding: 0;
    height: 57px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 1440px) {
        width: 100vw; 
    }
}

.atBottom {
    position: absolute;
    bottom: 70px;
    right: 0;
    width: calc(100% + 80px) !important;
    max-width: 1440px;
    margin: 0 auto;

    @media (max-width: 1440px) {
        width: 100vw;
        left: -40px;
        transform: none;
    }
}

.pagination {
    position: relative;
}

.imageMapIcon {
    width: 20px;
    height: 20px;
    margin-right: 16px;
}

.textShowmap {
    font-family: Avenir;
    font-size: 18px;
    font-weight: 800;
    line-height: 24.59px;
    letter-spacing: -0.02em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #FFFFFF;
    margin: 0;
    padding: 0;
}

.mobileContentForMap {
    @media (max-width: 768px) {
        padding-left: 0;
        padding-right: 0;
    }
}

.mb-60 {
    margin-bottom: 60px;
}