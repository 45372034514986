.dayPickers {
    display: flex;
    width: 100%;

    /* Обертка для кнопок и месяца */
    :global .rdp-month_caption {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-bottom: 21px;
    }

    :global .rdp-week {
        margin-bottom: 12px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    :global .rdp-weekdays {
        margin-bottom: 12px;
    }

    /* Щирина календаря */
    :global .rdp-month_grid {
        height: 296px;
    }

    /* Расположение стрелок */
    :global .rdp-nav {
        width: 392px;
        justify-content: space-between;
    }

    /* Изменение цвета стрелок */
    :global .rdp-button_previous svg,
    :global .rdp-button_next svg {
        fill: #0F172A;
    }

    :global .rdp-root {
        margin-right: -12px;
        --rdp-accent-color: #7623FF;
        /* Цвет акцента */
        --rdp-accent-background-color: #F1E9FF;
        /* Цвет фона акцента */
        --rdp-months-gap: 40px;
        --rdp-nav-height: 25px;
    }

    /* Изменение шрифта для заголовка месяца */
    :global .rdp-month_caption {
        font-family: Avenir;
        font-size: 18px;
        font-weight: 800;
        line-height: 24.59px;
        letter-spacing: -0.02em;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
    }

    /* Изменение шрифта для дней недели */
    :global .rdp-weekday {
        font-family: Avenir;
        font-size: 14px;
        font-weight: 400;
        line-height: 19.12px;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
    }

    /* Изменение шрифта для дней */
    :global .rdp-day_button {
        font-family: Avenir;
        font-size: 14px;
        font-weight: 400;
        line-height: 19.12px;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #2E2E2E;
        margin-right: 12px;
    }

    :global .rdp-range_start .rdp-day_button {
        color: #ffffff;
    }

    :global .rdp-range_end .rdp-day_button {
        color: #ffffff;
    }

    :global .rdp-day_disabled {
        color: #BEBEBE;
    }
}

.secondCalendar {
    width: 100%;
    padding-left: 40px;
}