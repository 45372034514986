.modal {
    position: absolute;
    bottom: 60px;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 4000;

    @media (max-width:1020px) {
        bottom: 0;
        left: 0;
        right: 0;
        position: fixed;
        width: 100vw;
        max-width: 100%;
        overflow-x: hidden;
    }
}

.isBottom {
    bottom: auto;
    top: 60px
}

.content {
    width: 526px;
    background: #ffffff;
    box-shadow: 0px 25px 50px -12px #00000040;
    border-radius: 16px;
    position: relative;
    z-index: 10;
    max-height: 216px;
    overflow-x: hidden;

    @media (max-width:1020px) {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        width: 100%;
        max-width: 100%;
        max-height: 70vh;
        min-height: 70vh;
    }
}

.suggestionItem {
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 54px;
    width: 100%;
    max-width: 100%;
    position: relative;
    z-index: 1;

    :hover {
        background: #F1F2F2;
        z-index: 1;
    }

    @media (max-width:1020px) {
        height: 52px;
    }
}

.image {
    width: 20px;
    height: 20px;
    background-image: url("../../assets/images/location-marker-blue.png");
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
    z-index: 2;
    position: absolute;
    left: 20px;
    pointer-events: none;
}

.text {
    font-family: Avenir;
    font-size: 16px;
    font-weight: 400;
    line-height: 21.86px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #000000;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    padding-left: 48px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-top: 16px;
    width: 100%;
    max-width: 100%;
    height: 54px;

    @media (max-width:1020px) {
        font-size: 14px;
        line-height: 19.12px;
        height: 52px;
    }
}

.mobile {
    display: none;

    @media (max-width:1020px) {
        display: flex;
        width: 100%;
        max-width: 100%;
        overflow-x: hidden;
    }
}

.top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 100%;
    overflow-x: hidden;
    height: 65px;
    padding-left: 20px;
    padding-right: 20px;
}

.textTop {
    font-family: Avenir;
    font-size: 18px;
    font-weight: 800;
    line-height: 24.59px;
    letter-spacing: -0.02em;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #000000;
    margin: 0;
    padding: 0;
}

.buttonClose {
    width: 24px;
    height: 24px;
    cursor: pointer;

    &:hover {
        opacity: 0.7;
    }
}

.searchBlock {
    display: flex;
    width: 100%;
    height: 56px;
    align-items: center;
    background: #F1F2F2;

    img {
        width: 24px;
        height: 24px;
        padding-left: 20px;
        margin-right: 4px;
    }
}

.searchBlockWithFlag {
    display: flex;
    width: 100%;
    height: 56px;
    align-items: center;

    img {
        width: 24px;
        height: 24px;
        padding-left: 20px;
        margin-right: 4px;
    }
}

.textSearch {
    font-family: Avenir;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.12px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin: 0;
    padding: 0;
    color: #747E7F;
    width: 100%;
    height: 100%;
    border: none;
    background: none;
    outline: none;

    &:focus {
        outline: none;
        border: none;
        touch-action: none;
    }
}

.textSearchBlack {
    font-family: Avenir;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.12px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #000000;
    margin: 0;
    padding: 0;
}

.filteredSuggestions {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    max-height: 216px;
    width: 100%;

    @media (max-width:1020px) {
        max-height: calc(70vh - 167px);
    }

    // Стили для скроллбара
    ::-webkit-scrollbar {
        width: 4px;
        /* Ширина скроллбара */
    }

    ::-webkit-scrollbar-thumb {
        background-color: #B8BCBC;
        /* Цвет ползунка */
    }

    ::-webkit-scrollbar-track {
        background-color: #F1F2F2;
        /* Цвет трека (фон под ползунком) */
    }

    // Для Firefox
    scrollbar-width: thin;
    /* Уменьшенная ширина */
    scrollbar-color: #B8BCBC #F1F2F2;
    /* Цвет ползунка и трека */
}

.clearButtonIcon {
    width: 20px;
    height: 20px;
    margin-left: 8px;
    padding-right: 17px;
    cursor: pointer;
}