.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 4000;
    max-width: 100%;
    max-height: 100%;
    overflow-x: hidden;
    width: 100vw;
    height: 100vw;
    cursor: default;
    display: flex;
}

.modal {
    background: #fff;
    box-shadow: 0px 25px 50px -12px #00000040;
    border-radius: 24px;
    padding: 24px;
    width: 945px;
    box-sizing: border-box;
    min-height: 803px;
    position: relative;
    margin: auto;
}

.modalContent {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.closeButton {
    position: absolute;
    width: 54px;
    height: 54px;
    top: 0;
    right: -64px;
    cursor: pointer;
    background: #FFFFFF4D;
    border: 1.5px solid #FFFFFF;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        width: 24px;
        height: 24px;
    }
}

.top {
    display: flex;
    width: 100%;
    height: 496px;
    margin-bottom: 32px;
}

.firstColumn {
    width: 444.5px;
    height: 100%;
    margin-right: 8px;

    .image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
}

.secondColumn {
    display: flex;
    flex-direction: column;
    width: 218.25px;
    margin-right: 8px;
}

.secondImageBlock {
    width: 100%;
    height: 244px;
    margin-bottom: 8px;

    .image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.threeColumn {
    width: 218.43px;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.noImage {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #E2E2E2;
    flex-direction: column;
}

.relative {
    position: relative;
}

.showAllPhotosBlock {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 199px;
    height: 88px;
    background: #FAFBFCCC;
    border-radius: 15px;
    backdrop-filter: blur(12.300000190734863px);
    top: 149px;
    left: 8.5px;
    cursor: pointer;
}

.quantityPhotos {
    font-family: Avenir;
    font-size: 36px;
    font-weight: 400;
    line-height: 38.6px;
    letter-spacing: -0.04em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #7623FF;
    margin: 0;
    padding: 0;
    padding-left: 30px;
    margin-right: 15px;
}

.textShowPhotos {
    font-family: Avenir;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #000000;
    margin: 0;
    padding: 0;
    padding-right: 27px;
}

.middle {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 24px;
}

.middleLeftBlock {
    display: flex;
    flex-direction: column;
    margin-right: 32px;
    width: 100%;
}

.title {
    font-family: Avenir;
    font-size: 24px;
    font-weight: 800;
    line-height: 32.78px;
    letter-spacing: -0.02em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #000000;
    margin: 0;
    padding: 0;
    margin-bottom: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 532px;
}

.parameters-block {
    font-family: Avenir;
    font-size: 16px;
    font-weight: 400;
    line-height: 21.86px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin: 0;
    padding: 0;
    margin-bottom: 8px;
    color: #747E7F;
}

.hostIfno {
    display: flex;
    width: 100%;
    height: 54px;
    align-items: center;
}

.imageHost {
    width: 54px;
    height: 54px;
    object-fit: cover;
    object-position: center;
    margin-right: 8px;
    border-radius: 50%;
}

.hostName {
    font-family: Avenir;
    font-size: 18px;
    font-weight: 800;
    line-height: 24.59px;
    letter-spacing: -0.02em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #000000;
    margin: 0;
    padding: 0;
    margin-right: 16px;
}

.reviewImage {
    width: 24px;
    height: 24px;
    margin-right: 8px;
}

.textReviews {
    font-family: Avenir;
    font-size: 18px;
    font-weight: 800;
    line-height: 24.59px;
    letter-spacing: -0.02em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #000000;
    margin: 0;
    padding: 0;
}

.middleRightBlock {
    width: 333px;
    height: 125px;
    min-width: 333px;
}

.leaflet-map {
    width: 100%;
    height: 100%;
    border-radius: 16px;
}

.bottom {
    display: flex;
    width: 100%;
    height: 78px;
    padding-top: 24px;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
}

.bottomLeftBlock {
    display: flex;
    align-items: center;
}

.dateBlock {
    width: 272px;
    height: 54px;
    background: #F1F2F2;
    border: 1px solid #0000001A;
    border-radius: 16px;
    display: flex;
    align-items: center;
    margin-right: 16px;
    position: relative;
    cursor: pointer;
}

.iconDate {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    padding-left: 16px;
}

.textDate {
    font-family: Avenir;
    font-size: 16px;
    font-weight: 400;
    line-height: 21.86px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #000000;
    margin: 0;
    padding: 0;
}

.guestBlock {
    display: flex;
    align-items: center;
    width: 103px;
    height: 54px;
    background: #F1F2F2;
    border: 1px solid #0000001A;
    border-radius: 16px;
    position: relative;
    cursor: pointer;
}

.guestsIcon {
    width: 20px;
    height: 20px;
    padding-left: 16px;
    margin-right: 8px;
}

.textGuests {
    font-family: Avenir;
    font-size: 16px;
    font-weight: 400;
    line-height: 21.86px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #000000;
    margin: 0;
    padding: 0;
}

.bottomRightBlock {
    display: flex;
    align-items: center;
    height: 54px;
}

.totalCostText {
    font-family: Avenir;
    font-size: 24px;
    font-weight: 800;
    line-height: 32.78px;
    letter-spacing: -0.02em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #000000;
    margin: 0;
    padding: 0;
    margin-right: 16px;
}

.costForNightText {
    font-family: Avenir;
    font-size: 16px;
    font-weight: 400;
    line-height: 21.86px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #747E7F;
    margin: 0;
    padding: 0;
    margin-right: 16px;
}

.buttonBooking {
    min-width: 116px;
    height: 54px;
    background: #7623FF;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Avenir;
    font-size: 18px;
    font-weight: 400;
    line-height: 24.59px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #ffffff;
    cursor: pointer;
    margin: 0;
    padding: 0;
    margin-right: 16px;

    &:hover {
        opacity: 0.7;
    }
}

.buttonDetails {
    min-width: 104px;
    height: 54px;
    border-radius: 16px;
    border: 1px solid #7623FF;
    font-family: Avenir;
    font-size: 18px;
    font-weight: 400;
    line-height: 24.59px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #7623FF;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
        opacity: 0.7;
    }
}

.paddingInButton {
    margin: 0;
    padding: 0;
    padding-left: 8px;
    padding-right: 8px;
}

.date-picker {
    position: absolute;
    bottom: 100px;
    z-index: 3000;
    background: white;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 16px;
    width: max-content;
    background-color: white;
}

.textShowCost {
    margin: 0;
    padding: 0;
    cursor: pointer;
    font-family: Avenir;
    font-size: 18px;
    font-weight: 400;
    line-height: 24.59px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}

.iconLogo {
    width: 47px;
    height: 66px;
    object-fit: cover;
    object-position: center;
    padding-top: 8px;
}

.textNoPhoto {
    font-family: Avenir;
    font-weight: 800;
    font-size: 18px;
    line-height: 40px;
    letter-spacing: -2%;
    color: #CFCFCF;
    text-align: center;
    margin: 0;
    padding: 0;
}