.language-switcher {
  position: relative;
  min-width: 58px;
  height: 24px;
  display: flex;
  align-items: center;
  -webkit-tap-highlight-color: transparent;

  &:focus {
    outline: none;
    box-shadow: none;
  }

  .language-block {
    display: flex;
    cursor: pointer;
    align-items: center;
    -webkit-tap-highlight-color: transparent;

    &:focus {
      outline: none;
      box-shadow: none;
    }

    &:hover {
      opacity: 0.7;
    }
  }

  .text-language {
    margin: 0;
    padding: 0;
    font-family: Avenir;
    font-size: 14px;
    font-weight: 800;
    line-height: 19.12px;
    text-align: right;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #000000;
    width: 100%;
  }

  .white {
    color: #ffffff;
  }

  .language-button {
    width: 24px;
    height: 24px;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    margin-right: 8px;

    .image-icon {
      width: 24px;
      height: 24px;
      object-fit: contain;
    }
  }

  .mr-0 {
    margin-right: 0;
  }

  .language-menu {
    position: absolute;
    top: 45px;
    right: -24px;
    background: #fff;
    border-radius: 16px;
    box-shadow: 0px 25px 50px -12px #00000040;
    z-index: 10;
    display: flex;
    flex-direction: column;
    width: 524px;
    padding: 24px;
    box-sizing: border-box;

    @media (max-width:1020px) {
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      border-radius: 0;
      max-height: 100vh;
      overflow-y: auto;
      padding: 0;
      max-width: 100%;
      overflow-x: hidden;
      width: 100vw;
      
      // Стили для скроллбара
      ::-webkit-scrollbar {
        width: 4px;
        /* Ширина скроллбара */
      }

      ::-webkit-scrollbar-thumb {
        background-color: #B8BCBC;
        /* Цвет ползунка */
      }

      ::-webkit-scrollbar-track {
        background-color: #F1F2F2;
        /* Цвет трека (фон под ползунком) */
      }

      // Для Firefox
      scrollbar-width: thin;
      /* Уменьшенная ширина */
      scrollbar-color: #B8BCBC #F1F2F2;
      /* Цвет ползунка и трека */
    }

    .languagesBlock {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      gap: 16px;
    }

    .language-option {
      display: flex;
      align-items: center;
      background: none;
      border: none;
      width: 148px;
      height: 24px;
      cursor: pointer;
      margin: 0;
      padding: 0;
      margin-bottom: 8px;

      &:nth-last-child(-n+3) {
        margin-bottom: 0;
      }

      &:hover {
        background: #f0f0f0;
      }
    }

    .language-icon {
      width: 24px;
      height: 24px;
      margin-right: 8px;
      object-fit: contain;

      @media (max-width:1020px) {
        margin-right: 0;
      }
    }

    .desktop {

      @media (max-width:1020px) {
        display: none;
      }
    }

    .mobile {
      display: none;

      @media (max-width:1020px) {
        display: flex;
        flex-direction: column;
        width: 100%;
      }
    }

    .buttonMobile {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: none;
      width: 100%;
      border: none;
      cursor: pointer;
      height: 64px;
      padding-left: 20px;
      padding-right: 20px;

      &:hover {
        background: #F1F2F2;
      }
    }

    .countryName {
      font-family: Avenir;
      font-size: 16px;
      font-weight: 400;
      line-height: 21.86px;
      text-align: center;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #000000;
      padding: 0;
      margin: 0;
    }
  }
}

.text {
  width: 100%;
  font-family: Avenir;
  font-size: 24px;
  font-weight: 800;
  line-height: 32.78px;
  letter-spacing: -0.02em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000000;
  margin: 0;
  padding: 0;
  margin-bottom: 16px;
}

.mobileText {
  font-family: Avenir;
  font-size: 18px;
  font-weight: 800;
  line-height: 24.59px;
  letter-spacing: -0.02em;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  padding: 0;
  margin: 0;
  color: #000000;
}

.buttonClose {
  width: 24px;
  height: 24px;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
}

.top {
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: space-between;
  height: 65px;
  box-sizing: border-box;
}

.topInBurgerMenu {
  height: 64px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  align-items: center;
  -webkit-tap-highlight-color: transparent;

  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.textInBurgerMenu {
  font-family: Avenir;
  font-size: 16px;
  font-weight: 400;
  line-height: 21.86px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000000;
  margin: 0;
  padding: 0;
}

.heightInBurgerMenu {
  height: 64px;
  cursor: pointer;

  &:hover {
    background: #F1F2F2;
  }
}

.languageName {
  width: 116px;
  font-family: Avenir;
  font-size: 16px;
  font-weight: 400;
  line-height: 21.86px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000000;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}