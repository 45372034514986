.right-sticky-content {
    position: sticky;
    top: 137px;
    width: 439px;

    @media (max-width: 1439px) {
        position: fixed;
        bottom: 0;
        width: 100vw;
        left: 0;
        top: unset;
        z-index: 2000;
    }

    .sticky-block {
        border: 1px solid #E2E2E2;
        background: #ffffff;
        border-radius: 24px;
        box-shadow: 0px 9px 10px 0px #0000001A;
        display: flex;
        flex-direction: column;
        padding: 24px;
        box-sizing: border-box;

        .price-info {
            display: flex;
            justify-content: space-between;

            h2 {
                margin: 0;
                padding: 0;
                font-family: Avenir;
                font-size: 24px;
                font-weight: 800;
                line-height: 32.78px;
                letter-spacing: -0.02em;
                text-align: left;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;
                color: #000000;
            }

            span {
                margin: 0;
                padding: 0;
                font-family: Avenir;
                font-size: 16px;
                font-weight: 400;
                line-height: 21.86px;
                text-align: left;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;
                color: #747E7F;
            }
        }

        .date-inputs {
            display: flex;
            gap: 16px;
            margin-bottom: 16px;
            margin-top: 16px;

            .date-input {
                display: flex;
                flex-direction: column;
                justify-content: center;
                border-radius: 16px;
                min-height: 54px;
                width: 100%;
                background: #F1F2F2;
                border: 1px solid #0000001A;
                cursor: pointer;

                &:hover {
                    opacity: 0.7;
                }

                .date-input-label {
                    padding: 0;
                    margin: 0;
                    padding-left: 16px;
                    font-family: Avenir;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 16.39px;
                    text-align: left;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;
                    color: #747E7F;
                    padding-top: 8px;
                }

                .date-input-placeholder {
                    padding: 0;
                    margin: 0;
                    padding-left: 16px;
                    font-family: Avenir;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 21.86px;
                    text-align: left;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;
                    color: #000000;
                    padding-bottom: 8px;
                }
            }
        }

        .guest-dropdown {
            position: relative;
            margin-bottom: 16px;

            button {
                width: 100%;
                padding: 0;
                border-radius: 16px;
                height: 54px;
                background: #F1F2F2;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: space-between;
                border: 1px solid #0000001A;

                &:hover {
                    opacity: 0.7;
                }

                .dropdown-icon {
                    width: 20px;
                    height: 20px;
                    padding-right: 17px;
                }

                .guest-dropdown-left {
                    display: flex;
                    flex-direction: column;
                    padding-left: 16px;

                    .guest-label {
                        margin: 0;
                        padding: 0;
                        font-family: Avenir;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 21.86px;
                        text-align: left;
                        text-underline-position: from-font;
                        text-decoration-skip-ink: none;
                        color: #000000;
                    }
                }
            }

            .guest-dropdown-menu {
                position: absolute;
                top: 100%;
                left: 0;
                width: 100%;
                background: #ffffff;
                border-radius: 16px;
                box-shadow: 0px 9px 10px 0px #0000001A;
                border: 1px solid #E2E2E2;
                z-index: 10;
                width: 391px;
                padding-bottom: 24px;

                .guest-item {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding-left: 16px;
                    padding-right: 16px;

                    .guest-labels {
                        display: flex;
                        flex-direction: column;
                        padding-top: 16px;
                        padding-bottom: 16px;

                        .guest-title {
                            margin: 0;
                            padding: 0;
                            font-family: Avenir;
                            font-size: 16px;
                            font-weight: 400;
                            line-height: 21.86px;
                            text-align: left;
                            text-underline-position: from-font;
                            text-decoration-skip-ink: none;
                            color: #000000;
                        }

                        .guest-subtitle {
                            margin: 0;
                            padding: 0;
                            font-family: Avenir;
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 16.39px;
                            text-align: left;
                            text-underline-position: from-font;
                            text-decoration-skip-ink: none;
                            color: #747E7F;
                        }

                    }

                    .guest-controls {
                        display: flex;
                        align-items: center;

                        button {
                            width: 36px;
                            height: 36px;
                            background: none;
                            cursor: pointer;

                            .minus-icon {
                                width: 36px;
                                height: 36px;
                            }

                            .plus-icon {
                                width: 36px;
                                height: 36px;
                            }
                        }

                        span {
                            margin-left: 8px;
                            margin-right: 8px;
                            font-family: Avenir;
                            font-size: 16px;
                            font-weight: 400;
                            line-height: 21.86px;
                            text-align: left;
                            text-underline-position: from-font;
                            text-decoration-skip-ink: none;
                            color: #000000;
                        }
                    }
                }

                p {
                    padding: 0;
                    margin: 0;
                    padding: 16px;
                    margin-bottom: 16px;
                    font-family: Avenir;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 16.39px;
                    text-align: left;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;
                    color: #747E7F;
                }

                .close-button {
                    width: 100%;
                    height: 22px;
                    padding: 0;
                    border: none;
                    cursor: pointer;
                    font-family: Avenir;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 21.86px;
                    text-align: center;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;
                    color: #7623FF;
                    display: flex;
                    justify-content: center;
                    background: none;
                }
            }
        }

        .availability-button {
            width: 100%;
            height: 54px;
            padding: 0;
            border-radius: 16px;
            cursor: pointer;
            background-color: rgba(118, 35, 255, 0.5);
            font-family: Avenir;
            font-size: 18px;
            font-weight: 400;
            line-height: 24.59px;
            text-align: center;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: #ffffff;
            border: none;

            &:hover {
                opacity: 0.7;
            }
        }
    }
}

/* Расположение стрелок */
:global .rdp-nav {
    width: 391px;
    justify-content: space-between;
}

/* Изменение цвета стрелок */
:global .rdp-button_previous svg,
:global .rdp-button_next svg {
    fill: #0F172A;
}

:global .rdp-root {
    margin-right: -12px;
    --rdp-accent-color: #7623FF;
    /* Цвет акцента */
    --rdp-accent-background-color: #F1E9FF;
    /* Цвет фона акцента */
    --rdp-months-gap: 40px;
    --rdp-nav-height: 25px;
}

/* Изменение шрифта для заголовка месяца */
:global .rdp-month_caption {
    font-family: Avenir;
    font-size: 18px;
    font-weight: 800;
    line-height: 24.59px;
    letter-spacing: -0.02em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}

/* Изменение шрифта для дней недели */
:global .rdp-weekday {
    font-family: Avenir;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.12px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}

/* Изменение шрифта для дней */
:global .rdp-day_button {
    font-family: Avenir;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.12px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #2E2E2E;
    margin-right: 12px;
}

:global .rdp-range_start .rdp-day_button {
    color: #ffffff;
}

:global .rdp-range_end .rdp-day_button {
    color: #ffffff;
}

:global .rdp-day_disabled {
    color: #BEBEBE;
}

/* Обертка для кнопок и месяца */
:global .rdp-month_caption {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 21px;
}

:global .rdp-week {
    margin-bottom: 12px;

    &:last-child {
        margin-bottom: 0;
    }
}

:global .rdp-weekdays {
    margin-bottom: 12px;
}

.fixBottomMobileBlock {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100vw;
    height: 86px;
    padding-left: 40px;
    padding-right: 40px;
    box-sizing: border-box;
    background: #FFFFFF;
    border-bottom: 1px solid #E2E2E2;
    box-shadow: 0px -2px 3px 0px #0000001A;

    @media (max-width: 1220px) {
        padding-left: 20px;
        padding-right: 20px;
    }
}

.leftFixedBlock {
    display: flex;
    flex-direction: column;
}

.textPriceNight {
    font-family: Avenir;
    font-weight: 800;
    font-size: 16px;
    line-height: 21.86px;
    letter-spacing: 0%;
    color: #000000;
    text-align: left;
    margin: 0;
    padding: 0;
}

.textDate {
    font-family: Avenir;
    font-weight: 400;
    font-size: 14px;
    line-height: 19.12px;
    letter-spacing: 0%;
    color: #747E7F;
    text-align: left;
    margin: 0;
    padding: 0;
}

.buttonReserve {
    height: 54px;
    background: #7623FF;
    border: none;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    cursor: pointer;
}

.textInButtonReserve {
    margin: 0;
    padding: 0;
    font-family: Avenir;
    font-weight: 400;
    font-size: 18px;
    line-height: 24.59px;
    letter-spacing: 0%;
    color: #ffffff;
    text-align: center;
    padding-left: 24px;
    padding-right: 24px;
}

.priceBlock {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    width: 100%;
}

.textTop {
    font-family: Avenir;
    font-weight: 400;
    font-size: 16px;
    line-height: 21.86px;
    letter-spacing: 0%;
    color: #000000;
    text-align: left;
    margin: 0;
    padding: 0;
    margin-bottom: 16px;
}

.blocks {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 16px;
}

.textUnderLine {
    font-family: Avenir;
    font-weight: 400;
    font-size: 16px;
    line-height: 21.86px;
    letter-spacing: 0%;
    text-decoration: underline;
    text-decoration-style: solid;
    text-decoration-thickness: Auto;
    color: #000000;
    margin: 0;
    padding: 0;
    text-align: left;
}

.textPrice {
    font-family: Avenir;
    font-weight: 400;
    font-size: 16px;
    line-height: 21.86px;
    letter-spacing: 0%;
    color: #000000;
    text-align: right;
    margin: 0;
    padding: 0;
}

.textPriceDiscount {
    font-family: Avenir;
    font-weight: 400;
    font-size: 16px;
    line-height: 21.86px;
    letter-spacing: 0%;
    color: #7623FF;
    margin: 0;
    padding: 0;
    text-align: right;
}

.totalCostBlock {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-top: 16px;
    border-top: 1px solid #E2E2E2;
}

.textTotalCost {
    font-family: Avenir;
    font-weight: 800;
    font-size: 16px;
    line-height: 21.86px;
    letter-spacing: 0%;
    color: #000000;
    margin: 0;
    padding: 0;
    text-align: left;
}

.textTotalPirce {
    font-family: Avenir;
    font-weight: 800;
    font-size: 16px;
    line-height: 21.86px;
    letter-spacing: 0%;
    text-align: right;
    color: #000000;
    margin: 0;
    padding: 0;
    text-align: right;
}