.modal {
    position: absolute;
    bottom: 60px;
    left: 0;
    background: #ffffff;
    box-shadow: 0px 25px 50px -12px #00000040;
    min-width: 219px;
    height: 84px;
    border-radius: 16px;
    display: flex;
    align-items: center;
}

.content {
    display: flex;
    align-items: center;
}

.controls {
    display: flex;
    align-items: center;
    padding-right: 24px;
    padding-left: 26px;
}

.button {
    border: 1px solid #E2E2E2;
    background: #ffffff;
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 20px;

    img {
        width: 24px;
        height: 24px;
    }

    &:disabled {
        opacity: 0.2;
        cursor: not-allowed;
    }

    &:focus {
        outline: none;
    }
}

.value {
    font-family: Avenir;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.12px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #000000;
    padding: 0;
    margin: 0;
    margin-left: 8px;
    margin-right: 8px;
    min-width: 24px;
}

.title {
    font-family: Avenir;
    font-size: 14px;
    font-weight: 800;
    line-height: 19.12px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #000000;
    margin: 0;
    padding: 0;
    padding-left: 24px;
}