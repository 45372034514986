.container {
    display: flex;
    align-items: center;
    height: 22px;
    margin-bottom: 16px;
    width: 100%;
}

.option {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: 16px;
    transition: border-color 0.2s ease, background-color 0.2s ease;

    &:hover {
        opacity: 0.7;
    }

    &.selected {

        .icon {
            display: inline-block;
        }
    }

    .icon {
        width: 20px;
        height: 20px;
        margin-right: 8px;
        display: none;
    }

    span {
        font-family: Avenir;
        font-size: 16px;
        font-weight: 400;
        line-height: 21.86px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #0F172A;
        margin: 0;
        padding: 0;
    }
}

.checkBox {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    border: 1px solid #64748B;
    box-sizing: border-box;
    border-radius: 6px;
}