.section {
    box-sizing: border-box;
    max-width: 1440px;
    background: #FAFBFC;
    min-height: 100vh;
    position: relative;
    overflow: hidden;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
    position: relative;
    z-index: 1;

    &::before {
        content: "";
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: url("../../assets/images/icons-for-mainPage/backgroundImage.png");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        z-index: -1;


        @media (max-width: 768px) {
            background-image: url("../../assets/images/icons-for-mainPage/backgroundImageMobile.png");
        }
    }
}

.mainContent {
    display: flex;
    flex-direction: column;
}

.searchContent {
    display: flex;
    margin-top: 21px;
    justify-content: space-between;
    padding-left: 40px;

    @media (max-width: 1285px) {
        flex-direction: column;
        margin-top: 15px;
        padding-left: 20px;
        padding-right: 20px;
    }
}

.title {
    margin: 0;
    padding: 0;
    padding-left: 31px;
    margin-top: 33px;
    font-family: Avenir;
    font-size: 128px;
    font-weight: 400;
    line-height: 124px;
    letter-spacing: -0.02em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #ffffff;
    max-width: 980px;
    white-space: pre-line;
    overflow: hidden;
    border-right: 2px solid #ffffff;
    animation: blink 0.5s step-end infinite;
    min-height: 248px;
    display: flex;
    flex-direction: column;

    @media (max-width: 1180px) {
        font-size: 42px;
        line-height: 57.37px;
        padding-left: 20px;
        padding-right: 20px;
        min-height: 171px;
        margin-top: 37px;
    }
}
.typewriter {
    display: block;
}
.travelText {
    font-family: Avenir;
    font-size: 36px;
    font-weight: 400;
    line-height: 42px;
    letter-spacing: -0.02em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #ffffff;
    margin: 0;
    padding: 0;
    padding-top: 58px;
    padding-right: 16px;
    max-width: 348px;

    @media (max-width: 1285px) {
        padding-top: 17px;
        padding-bottom: 15px;
        font-family: Avenir;
        font-size: 18px;
        font-weight: 400;
        line-height: 24.59px;
        letter-spacing: -0.02em;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
    }
}

@keyframes blink {
    from {
        border-color: transparent;
    }

    to {
        border-color: #ffffff;
    }
}